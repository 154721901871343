import {Log, LogDom, LogFetch} from "../interfaces/logs";
import localforage from "localforage";
import {host} from "../environments/environments";

export const saveLog = (log: Log) => {
    (async function() {
        try {
            const logs: Log[] = await localforage.getItem<Log[] | null>('logs') ?? []
            logs.push(log);
            await localforage.setItem('logs', logs)
        } catch(err) {
            console.log(err)
        }
    })()
}

export const saveLogToServer = () => {
    (async function() {
        const logs: Log[] = await localforage.getItem<Log[] | null>('logs') ?? []
        const logsFetch: LogFetch[] = []
        const logsDom: LogDom[] =[]

        logs.forEach((log) => {
            switch (log.type) {
                case 'fetch':
                    logsFetch.push(log)
                    break;
                case 'dom':
                    logsDom.push(log)
                    break;
                default:
                    break;
            }
        })

        try {
            const response = await fetch(`${host}/mobile/log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    logsFetch,
                    logsDom
                })
            })

            if (response.ok) {
                await localforage.setItem('logs', [])
                console.log('Очистилось')
            }
        } catch(err) {
            console.log(err)
        }
    })()
}