import React, {FC, useState} from 'react';
import Logo from '../img/logo.png'
import Button from '../components/Button/Button'

import './DirectionPage.scss'
import {hostIdentity} from "../environments/environments";
import {Link} from "react-router-dom";
import {IconButton} from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Modal from "../components/Modal/Modal";
import {HELPERS} from "../constance/helps";
import NotificationBell from "../components/NotificationBell/NotificationBell";
import ConnectionStateIndicator from "../components/ConnectionStateIndicator/ConnectionStateIndicator";


interface DirectionPageProps {
    error: any
    noConnectToNetwork: any
}

const DirectionPage: FC<DirectionPageProps> = ({error, noConnectToNetwork}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [helperModalText, setHelperModalText] = useState('')

    const handleClickHelp = (helpText: string) => {
        setIsOpen(true)
        setHelperModalText(helpText)
    }

    const handleClickCloseHelperModal = () => {
        setIsOpen(false)
        setHelperModalText('')
    }

    return (
        <div className="wrap">
            <ConnectionStateIndicator />
            <NotificationBell onClickHelp={handleClickHelp} helpText={HELPERS['notification']}/>
            <img className="logo" src={Logo} alt="logo"/>
            <h1>Выберите направление</h1>
            <div className='top-button'>
                <Button href="/import" text="Импорт" isActive={!error} helpText={HELPERS['import']}
                        onClickHelp={handleClickHelp}/>
                <Button href="/export" text="Экспорт" isActive={!error} helpText={HELPERS['export']}
                        onClickHelp={handleClickHelp}/>
                <Button href="/directory" text="Справочник КО" isActive={!error} helpText={HELPERS['directory']}
                        onClickHelp={handleClickHelp}/>
                <Button href="/kfz" text="Справочник КФЗ" isActive={!error} helpText={HELPERS['kfz']}
                        onClickHelp={handleClickHelp}/>
                <Button href="/nsi" text="НСИ" isActive={!error} helpText={HELPERS['nsi']}
                        onClickHelp={handleClickHelp}/>
            </div>
            <div className="error">
                {error}
                {noConnectToNetwork}
            </div>
            <div className='bottom-button'>
                <Button href="/update" text="Обновить данные" isActive={noConnectToNetwork ? false : true}/>
            </div>
            <Link to='/support' className="link-logout">Техническая поддержка
                <IconButton aria-label="help"
                            style={{
                                color: '#80808087',
                                position: 'absolute',
                                right: '-40px',
                                top: '-25%'
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                handleClickHelp(HELPERS['support'])
                            }}>
                    <HelpOutlineOutlinedIcon/>
                </IconButton>
            </Link>
            <a className="link-logout" href={`${hostIdentity}/Authorization/logout`}>Выйти</a>
            <Modal isOpen={isOpen} onClose={handleClickCloseHelperModal}>
                <p className="notification-message">{helperModalText}</p>
            </Modal>
        </div>
    );
};

export default DirectionPage;