import React, {FC, useEffect, useState} from 'react';
import {useDebounce} from "../../hooks/useDebounce";

interface InputSearchProps {
    placeholder?: string
    onSearch: (str: string) => void
}

const InputSearch: FC<InputSearchProps> = ({placeholder = 'Поиск', onSearch}) => {

    const [search, setSearch] = useState('')

    const debounced = useDebounce(search)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.currentTarget.value)
    }

    useEffect(() => {
        onSearch(debounced)
    }, [debounced, onSearch])

    return (
        <input type="text"
               placeholder={placeholder}
               className={'form-input'}
               value={search}
               onChange={handleInputChange}
        />
    );
};

export default InputSearch;