import React, {FC, useEffect, useState} from 'react';
import FullAccordion, {FullAccordionProps} from "../FullAccordion/FullAccordion";
import {useGetData} from "../../hooks/useGetData";
import {HOST_DESKTOP} from "../../pages/NSIPage";
import {host} from "../../environments/environments";

interface CountryAccordionProps extends FullAccordionProps {
    countryId: number
    children?: React.ReactNode
}

interface IFile {
    id: string | null
    name: string
    mimeType?: string
    file: string | null
}

const CountryAccordion: FC<CountryAccordionProps> = ({countryId, expanded, ...props}) => {

    const [expd, setExpanded] = useState(expanded)
    const [files, loading, getFiles] = useGetData<IFile[]>(host + `/mobile/nsi/country/${countryId}`, true)

    useEffect(() => {
        setExpanded(expanded)
    }, [expanded])

    const handleCLick = () => {
        getFiles()
    }

    return (
       <FullAccordion onClick={handleCLick} {...props} >
           <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
               {loading ? <p>Загрузка...</p> : files?.length !== 0 ?
                   files?.map(file => <a href={(process.env.NODE_ENV === 'production' ? 'https://m.vniikr.ru' : 'https://m.vniikr.dev.asbc.lo') + file.file || ''} download>{file.name}</a>) :
                   <p>Файлы отсутствуют</p>
               }
           </div>
       </FullAccordion>
    );
};

export default CountryAccordion;