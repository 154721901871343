import React from 'react';
import { useAlerts } from '../../hooks/useAlerts';

import './AlertWrapper.scss';
import InformationAlert from "./InformationAlert/InformationAlert";
import {IAlertTypes} from "../../interfaces/alert";
const AlertWrapper = () => {

    const {alerts} = useAlerts()

    return (
        <div className="alert-wrapper">
            {alerts.length !==0 && alerts.map(alert => {
                    switch (alert.type) {
                        case IAlertTypes.INFORMATION:
                            return <InformationAlert key={alert.id} alert={alert} />
                        default:
                            return '';
                    }
                }
            )}
        </div>
    );
};

export default AlertWrapper;