import React, {FC, useEffect, useState} from 'react';
import Header from '../components/Header/Header'
import {UpdateItemsWithDeterminateStatus} from "../components/UpdateItem/UpdateItem";
import {Country, HaveUpdateResponse} from "../interfaces/interfaces";
import {host} from "../environments/environments";
import CheckBoxedAutocomplete from "../components/CheckboxedAutocomplete/CheckboxedAutocomplete";
import localforage from "localforage";
import {getNeedUpdate} from "../services/fetchDataFromServer";
import {saveLogToServer} from "../services/logging";
import {HELPERS} from "../constance/helps";
import Modal from "../components/Modal/Modal";
import {useDataBase} from "../hooks/useDataBase";

import './UpdatePage.scss'
import {Box, Divider, FormControlLabel, Paper} from '@mui/material';
import Checkbox from "@mui/material/Checkbox";

interface UpdatePageProps {

}

const UpdatePage: FC<UpdatePageProps> = () => {

    const {loadDataExport, loadKfzDb, loadQuarantsDb} = useDataBase()
    const [countries, setCountries] = useState<Country[]>([])
    const [selectedCountries, setSelectedCountries] = useState<Country[]>([])
    const [needUpdate, setNeedUpdate] = useState<HaveUpdateResponse | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [helperModalText, setHelperModalText] = useState('')
    const [selectAllCountries, setSelectAllCountries] = useState(false);

    useEffect(() => {
        setSelectedCountries(countries?.filter(country => country.disabled))
    }, [countries])

    useEffect(() => {
        checkUpdate()
    }, [])

    useEffect(() => {
        saveLogToServer()
    }, [])

    useEffect(() => {
        fetch(`${host}/mobile/country`)
            .then(response => {
                if (response.status !== 200) {
                    console.error('Ошибка получения стран')
                    return
                }
                return response.json()
            })
            .then((countries) => {
                setCountries(countries)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const handleClickHelp = (helpText: string) => {
        setIsOpen(true)
        setHelperModalText(helpText)
    }

    const handleClickCloseHelperModal = () => {
        setIsOpen(false)
        setHelperModalText('')
    }

    const checkUpdate = async () => {
        const haveUpdate = await getNeedUpdate()
        if (haveUpdate) {
            setNeedUpdate(haveUpdate)
        }
    }

    const handleChangeSelectedCountries = (countries: Country[]) => {
        setSelectedCountries(countries)
    }

    const handleDeleteDataClick = async () => {
        await localforage.clear();
        window.location.reload()
    }

    const handleClickRemoveAllCountries = () => {
        setSelectedCountries([])
    }

    return (
        <div className="wrap">
            <Header text="Обновление" onBack="/"/>
            <button onClick={handleDeleteDataClick} className="delete-button">Очистить данные</button>
            {needUpdate &&
                <>
                    <UpdateItemsWithDeterminateStatus
                        needUpdate={needUpdate.kfzIsNeedUpdate}
                        fileName={'kfz'} checkUpdate={checkUpdate}
                        onDownload={loadKfzDb}
                    >
                        <p className='update-label'>Справочник КФЗ</p>
                    </UpdateItemsWithDeterminateStatus>
                    <UpdateItemsWithDeterminateStatus
                        needUpdate={needUpdate.quarantObjectsIsNeedUpdate}
                        fileName='quarants'
                        checkUpdate={checkUpdate}
                        onDownload={loadQuarantsDb}
                    >
                        <p className='update-label'>Карантинные объекты</p>
                    </UpdateItemsWithDeterminateStatus>
                    <UpdateItemsWithDeterminateStatus needUpdate={needUpdate.dataExportIsNeedUpdate}
                                                      alignItems='flex-start' fileName='data_export'
                                                      bodyRequest={{
                                                          requestHash: selectedCountries?.map(country => country.id).join(''),
                                                          countriesIds: selectedCountries?.map(country => country.id) ?? []
                                                      }}
                                                      checkUpdate={checkUpdate}
                                                      onDownload={loadDataExport}
                                                      replacingText='Данные загружены'
                                                      additionalButtons={
                                                            <>
                                                                <button onClick={handleClickRemoveAllCountries}>Очистить</button>
                                                            </>
                                                      }
                    >
                        {countries &&
                            <CheckBoxedAutocomplete
                                selectedItems={selectedCountries}
                                items={countries}
                                onChange={handleChangeSelectedCountries}
                                helpText={HELPERS['update']}
                                onClickHelp={handleClickHelp}
                            />
                        }
                    </UpdateItemsWithDeterminateStatus>
                </>
            }
            <Modal isOpen={isOpen} onClose={handleClickCloseHelperModal}>
                <p className="notification-message">{helperModalText}</p>
            </Modal>
        </div>
    );
};

export default UpdatePage;

