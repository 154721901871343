
export const enum IAlertTypes {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    INFORMATION = 'INFORMATION'
}
export interface IAlert {
    id: string
    type: IAlertTypes
    title: string | React.ReactNode
    message: string | React.ReactNode
    properties?: Record<string, any>
}

export interface IAlertSuccess extends IAlert {
    type: IAlertTypes.SUCCESS
    onClick?: (alert: IAlertSuccess) => void
}

export interface IAlertFailed extends IAlert {
    type: IAlertTypes.FAILED
    onClick?: (alert: IAlertFailed) => void
    code?: string
}

export interface IAlertInformation extends IAlert {
    type: IAlertTypes.INFORMATION
    onClick?: (alert: IAlertInformation) => void
    onRead: (alert: IAlertInformation) => void
    onDelete: (alert: IAlertInformation) => void
}

export type IAlertType = IAlertSuccess | IAlertFailed | IAlertInformation