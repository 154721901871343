import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import initSqlJs from 'sql.js'
import { SQLContext } from '../contexts/SQLContext';

interface SQLProviderProps {

}
const SQLProvider: FC<SQLProviderProps> = ({children}: PropsWithChildren<SQLProviderProps>) => {

    const [SQLService, setSQLService] = useState<any | null>(null)

    useEffect(() => {
        (async () => {
            try {
                const SQL = await initSqlJs({locateFile: () => '/sql-wasm.wasm'})
                setSQLService(SQL)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    return (
        <SQLContext.Provider value={{SQLService: SQLService}}>
            {children}
        </SQLContext.Provider>
    );
};

export default SQLProvider;