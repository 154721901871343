import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {getIdByLatName, isFileNotEmpty, isFileNotEmptyByLatName} from "../../services/fetchData";
import {Database} from "sql.js";

interface LinkToFileProps {
    db: Database,
    latName: string,
    title: string,
    fileName: string,
    tableName: string
}

const LinkToFileByLatName: FC<LinkToFileProps> = ({db, latName,title, fileName, tableName}) => {
    const history = useHistory()
    const [isNotEmpty, setIsNotEmpty] = useState(false)
    const [id, setId] = useState('')

    const goToFileView = () => {
        history.push(`/file?id=${id}&fileName=${fileName}&tableName=${tableName}`)
    }

    useEffect(() => {
        const isNotEmpty = isFileNotEmptyByLatName(fileName, tableName, db, latName)
        setIsNotEmpty(isNotEmpty)
        if (isNotEmpty) {
            setId(getIdByLatName(tableName, db, latName) ?? '')
        }
    }, [fileName, tableName, db, latName])

    return (
        <>
            {
                isNotEmpty &&
                <p
                    className="link"
                    onClick={goToFileView}
                > {title}</p>
            }
        </>
    )
};

export default LinkToFileByLatName;