import moment from 'moment';
import localforage from "localforage";
import { Database } from 'sql.js';
export const getOnlyDigits = (str: string) => {
    return str.replace(/[^0-9]/g, '')
}

export const getLatNameFromFullName = (fullName: string) => {
    const allMatches = fullName.match(/.\((.*)\)/gm)
    return (allMatches && allMatches.length !== 0) ? allMatches[0].trim().replace(/^\(|\)$/g, '') : ''
}

export const mergedUint8Arrays = (chunks: Uint8Array[]): Uint8Array => {
    let length = 0
    let offset = 0

    chunks.forEach(chunk => {
        length += chunk.length
    })

    const mergedArray = new Uint8Array(length)

    chunks.forEach(chunk => {
        mergedArray.set(chunk, offset);
        offset += chunk.length;
    })

    return mergedArray
}

export function debounce <T extends (...params: any[]) => void>(func: T, delay: number = 500): T {
    let timeoutID: number | null = null;
    return function(this: any, ...args: any[]) {
        console.log(timeoutID)
        if (timeoutID) {
            console.log(timeoutID)
            clearTimeout(timeoutID)
        }
        timeoutID = window.setTimeout(() => {func.apply(this, args)}, delay);
    } as T;
}

export const validatePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phone = event.currentTarget.value
    let clearPhone = phone.replace(/[^0-9]/g, '')
    let selectionStart = event.currentTarget.selectionStart

    if (!clearPhone) return ''

    if (phone.length !== selectionStart) {
        if ((event.nativeEvent as any).data && /\D/g.test((event.nativeEvent as any).data)) {
            return clearPhone
        }
        return event.currentTarget.value
    }

    return transformPhone(clearPhone)
}

export const transformPhone = (phone: string) => {
    let formattedPhone = ''

    if (['7', '8', '9'].indexOf(phone[0]) > -1) {
        if (phone[0] === '9') phone = '7' + phone
        let firstSymbols = (phone[0] === '8') ? '8' : '+7'
        formattedPhone = firstSymbols + ' '
        if (phone.length > 1) {
            formattedPhone += "(" + phone.substring(1, 4)
        }
        if (phone.length >= 5) {
            formattedPhone += ') ' + phone.substring(4, 7)
        }
        if (phone.length >= 8) {
            formattedPhone += '-' + phone.substring(7, 9)
        }
        if (phone.length >= 10) {
            formattedPhone += '-' + phone.substring(9, 11)
        }
    } else {
        formattedPhone = '+' + phone
    }

    return formattedPhone
}

export const saveDataBase = async (name: string, dataBase: Database) => {
    await localforage.setItem(name, dataBase.export())
    dataBase.run('PRAGMA foreign_keys = ON;')
}

export const compareDate = <T extends {createdAt: string}>(a: T, b: T) => {
    if (moment(a.createdAt).isAfter(b.createdAt)) {
        return 1
    } else {
        return -1
    }
}

export const swipePower = (offset: number, absDistance: number) =>  (offset / absDistance) * 100

export const getNotificationButtonWidth = (fullWidthBlock: number, countButtons: number, gap: number) => {
    return (fullWidthBlock / 2) / countButtons - gap
}

export const getNotificationButtonRightOffset = (widthButton: number, numberButtonOnRightSide: number, gap: number) => {
    return (widthButton * (numberButtonOnRightSide - 1)) + ((numberButtonOnRightSide - 1) * gap)
}