import React, {FC, PropsWithChildren} from 'react';

import './WrapAccordion.scss'

interface WrapAccordionProps {

}

const WrapAccordion = ({children}: PropsWithChildren<WrapAccordionProps>) => {
    return (
        <div className="accordion-wrap">
            {children}
        </div>
    );
};

export default WrapAccordion;