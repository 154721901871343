import React, { useEffect } from 'react';
import { useAlerts } from '../hooks/useAlerts';
import {useNotifications} from "../hooks/useNotifications";
import {useLocation} from "react-router-dom";
import Header from '../components/Header/Header';
import NotificationCard from '../components/NotificationCard/NotificationCard';
import WrapAccordion from '../components/WrapAccordion/WrapAccordion';

const NotificationPage = () => {

    const {removeAlert, alerts} = useAlerts()
    const {notifications, readNotification} = useNotifications()
    const {state}: {
        state: {
            notification_id?: string
        }
    } = useLocation()

    useEffect(() => {
        const unreadNotificationsIds = notifications.filter(notification => !notification.isRead).map(notification => notification.id)
        alerts.forEach(alert => {
            if (unreadNotificationsIds.includes(alert.properties?.notification_id)) {
                removeAlert(alert)
            }
        })
    }, [notifications, alerts]);

    return (
        <>
            <Header text={'Оповещения'} onBack={'/'}/>
            <WrapAccordion>
                {notifications.length !== 0 ?
                    notifications.map(notification => {
                        if (state && state?.notification_id && state.notification_id.toString() === notification.id.toString()) {
                            return <NotificationCard
                                key={notification.id}
                                notification={notification}
                            />
                        }
                        return <NotificationCard
                            key={notification.id}
                            notification={notification}
                            onClick={readNotification}
                        />
                    }) :
                    <p style={{textAlign: 'center'}}>Оповещений нет</p>
                }
            </WrapAccordion>
        </>
    );
};

export default NotificationPage;