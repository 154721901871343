import * as React from 'react';
import {FC} from 'react';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './CheckboxedAutocomplete.scss'
import {Country} from "../../interfaces/interfaces";
import {CssTextField, StyledPopper} from "./StyledComponents";
import Chip from "@mui/material/Chip";
import {Box, createFilterOptions, Divider, FilterOptionsState, FormControlLabel, IconButton, Paper} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {WithHelp} from "../../interfaces/ui";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon sx={{color: '#73c120'}} fontSize="small"/>;

interface CheckBoxedAutocompleteProps extends WithHelp {
    items: Country[]
    selectedItems: Country[]
    onChange: (value: Country[]) => void
    PaperComponent?: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>
}

const defaultFilterOptions = createFilterOptions();

const filterOptions = <T,>(options: T[], state: {inputValue: string}): T[] => {
    const {inputValue} = state
    if (!inputValue) return []
    return defaultFilterOptions(options as T[], state as FilterOptionsState<any>).slice(0, 10) as T[];
};

const CheckBoxedAutocomplete: FC<CheckBoxedAutocompleteProps> = ({items, selectedItems, onChange, helpText, onClickHelp, PaperComponent}) => {

    const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!helpText || !onClickHelp) return;
        event.preventDefault();
        event.stopPropagation();
        onClickHelp(helpText)
    }

    return (
        <Autocomplete
            filterOptions={filterOptions}
            multiple={true}
            options={items}
            disableCloseOnSelect
            disableClearable={true}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            PopperComponent={StyledPopper}
            value={selectedItems}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => {
                onChange(value)
            }}
            renderOption={(props, option, {selected}) => {
                return (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                        />
                        {option.name}
                    </li>
                )
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <div className='input_wrapper'>
                    <CssTextField {...params} placeholder={'Страны'}/>
                    {helpText && <IconButton aria-label="help"  onClick={handleHelpClick} style={{
                        color: '#80808087',
                        position: 'absolute',
                        right: '30px',
                        top: '5px',
                        zIndex: 100
                    }}>
                        <HelpOutlineOutlinedIcon />
                    </IconButton>}
                </div>
            )}
            PaperComponent={PaperComponent}
        />
    );
}

export default CheckBoxedAutocomplete;