import React, {PropsWithChildren, useEffect, useState} from 'react';
import {SocketContext} from "../contexts/SocketContext";
import {HubConnection, HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import {host} from "../environments/environments";
import {INotification} from "../interfaces/notification";
import {useAlerts} from "../hooks/useAlerts";
import {useNotifications} from "../hooks/useNotifications";
import {v4 as uuid} from "uuid";
import {IAlertTypes} from "../interfaces/alert";
import {useHistory} from 'react-router';

interface SocketProviderProps {

}

const SocketProvider = ({children}: PropsWithChildren<SocketProviderProps>) => {

    const {removeAlert} = useAlerts()
    const {addNotification, readNotification} = useNotifications()
    const history = useHistory()

    const [connection, setConnection] = useState<HubConnection | null>(null)
    const [state, setState] = useState<HubConnectionState>(HubConnectionState.Disconnected)

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(host + '/notifications')
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        return Math.random() * 10000;
                    } else {
                        return null;
                    }
                }
            })
            .build()
        setConnection(connection)
    }, []);

    useEffect(() => {
        startConnection()
        observableConnectionState()
    }, [connection]);

    useEffect(() => {
        if (!connection) return;
        const handleNetworkChange = () => {
            const isOnline = navigator.onLine;
            if (isOnline) {
                // Попытка реконнекта при восстановлении сети
                connection.start().then(() => {
                    console.log('Соединение восстановлено после восстановления сети');
                });
            }
        };

        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);

        return () => {
            window.removeEventListener('online', handleNetworkChange);
            window.removeEventListener('offline', handleNetworkChange);
        };
    }, [connection]);

    const startConnection = () => {
        if (!connection) return;
        connection.start()
            .then(() => {
                setState(connection.state)
                startObserves()
                connection.invoke('GetUnreadNotification')
            })
            .catch(err => {
                console.error(err)
            })
    }

    const observableConnectionState = () => {
        if (!connection) return;
        connection.onclose(() => {
            setState(connection.state)
        })
    }

    const startObserves = () => {
        observeNotifications()
    }

    const observeNotifications = () => {
        addObserver('NotifyChangeRequirement', (notification: INotification) => {
            addNotification(notification)
        })
    }

    const addObserver = (methodName: string, callback: (...args: any[]) => any) => {
        if (!connection) return;
        connection.on(methodName, callback)
    }

    const removeObserver = (methodName: string) => {
        if (!connection) return;
        connection.off(methodName)
    }

    return (
        <SocketContext.Provider value={{
            connection,
            state
        }}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;