import { User, UserManager } from 'oidc-client'
import { environmentAuth, METADATA_OIDC } from '../environments/environments'

class AuthService {
    private static _instance = new AuthService()

    userManager: UserManager

    constructor() {
        const config = {
            authority: environmentAuth.stsAuthority,
            client_id: environmentAuth.clientId,
            redirect_uri: environmentAuth.redirectUri,
            //post_logout_redirect_uri: environmentAuth.postLogoutRedirectUri,
            response_type: environmentAuth.responseType,
            scope: environmentAuth.clientScope,
            metadata: {
                ...METADATA_OIDC
            }
        }

        this.userManager = new UserManager(config)
        // this.userManager.events.addUserSignedOut(function () {
        //     console.log('User signed out')
        // })
    }

    static get instance() {
        return this._instance
    }

    getUser(): Promise<User | null> {
        return this.userManager.getUser()
    }

    login(): void {
        this.userManager.signinRedirect()
    }

    logout(): void {
        this.userManager.getUser()
            .then(user => {
                if (user) {
                    this.userManager.signoutRedirect({
                        'id_token_hint': user.id_token
                    })
                }
            })
    }
    
}

const Auth = AuthService.instance
export default Auth