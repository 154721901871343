export const DELETE_LAST_NOTIFICATION_FROM_NOTIFICATION = (id: string) => `
    DELETE FROM notification
    WHERE id=${id}
    returning ${id};
`

export const DELETE_LAST_NOTIFICATIONS_FROM_NOTIFICATION = (limit: number) => `
    DELETE from notification where id IN 
    (SELECT id from notification order by id desc limit ${limit})
    returning *;
`