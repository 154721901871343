import InputBase from '@mui/material/InputBase';
import {styled} from "@mui/material/styles";

const StyledInput = styled(InputBase)(({theme}) => ({
    '& .MuiInputBase-input': {
        borderRadius: '10px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid transparent',
        fontSize: 18,
        padding: '10px 26px 10px 12px',
        fontFamily: 'TTNormRegular, sans-serif',
        boxShadow: '0 4px 7px rgba(0, 0, 0, 0.25)',
        marginBottom: '0',
        '&:focus': {
            borderRadius: '10px',
            border: '1px solid #73c120',
        },
        '&.invalid': {
            borderRadius: '10px',
            border: '1px solid red'
        }
    },
}));

export default StyledInput