import React, {FC, MouseEventHandler, useState} from 'react';
import './VoiceSearch.scss'
import {useReactMediaRecorder} from "react-media-recorder-2";
import {host} from '../../environments/environments';

import microphoneImg from '../../img/microphone.svg'

interface VoiceSearchProps {
    onSetValue: (text: string) => void
}

const VoiceSearch: FC<VoiceSearchProps> = ({onSetValue}) => {

    const [active, setActive] = useState(false)

    const {startRecording, stopRecording} = useReactMediaRecorder({
        audio: true,
        onStart: () => {
            setActive(true)
        },
        onStop: (blobUrl: string, blob: Blob) => {
            setActive(false)
            const formData = new FormData()
            formData.append('Voice', blob)
            fetch(`${host}/mobile/voice`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(message => {
                    onSetValue(message.text)
                })
                .catch(err => console.log(err))
        }
    })


    const handleStart: MouseEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('start')
        startRecording();
    }

    const handleStop: MouseEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('stop')
        stopRecording();
    }

    return (
        <>
            {active ?
                <div className="active-voice" onClick={handleStop}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div> :
                <div onClick={handleStart}>
                    <img src={microphoneImg} alt="Микрофон"/>
                </div>
            }
        </>
    );
};

export default VoiceSearch;