import React, {FC, useCallback, useEffect, useState} from 'react';
import {Database} from 'sql.js';
import Header from '../components/Header/Header';
import {IDirectory} from '../interfaces/interfaces';
import {
    getDataDirectoryObject,
    getFile,
    getGRP1DirectoryObject,
    getGRP2DirectoryObject,
    getQuarantObjByName
} from '../services/fetchData'
import WrapAccordion from '../components/WrapAccordion/WrapAccordion'
import './DirectoryPage.scss'
import InputSearch from "../components/InputSearch/InputSearch";
import FullAccordion from "../components/FullAccordion/FullAccordion";
import LinkToFile from "../components/LinkToFile/LinkToFile";
import {useDebounceFunc} from "../hooks/useDebounceFunc";
import {useDebounce} from "../hooks/useDebounce";

interface DirectoryPageProps {
    onSetDirectory: (value: React.SetStateAction<IDirectory>) => void
    db: Database
    directoryObj: IDirectory
}

const DirectoryPage: FC<DirectoryPageProps> = ({onSetDirectory, db, directoryObj}) => {

    const [searched, setSearched] = useState(false)
    const [searchStr, setSearchStr] = useState('')
    const debounceSearch = useDebounce(searchStr, 400)

    useEffect(() => {
        if (db) {
            onSetDirectory(getGRP1DirectoryObject(db) as IDirectory)
        }
    }, [db, onSetDirectory])

    useEffect(() => {
        if (debounceSearch === '' || debounceSearch.length < 3) {
            setSearched(false)
            onSetDirectory(getGRP1DirectoryObject(db) as IDirectory)
            return
        }
        setSearched(true)
        const newDirectoryObj = getQuarantObjByName(db, debounceSearch)
        onSetDirectory(newDirectoryObj as IDirectory)
    }, [db, debounceSearch]);

    const setGRPD2DirectoryObject = (str: string) => {
        const grp2: {} = getGRP2DirectoryObject(db, str)
        onSetDirectory((prev) => {
            const newDirectory: IDirectory = {
                ...prev,
                [str]: {
                    ...prev[str],
                    ...grp2
                }
            }
            return newDirectory
        })
    }

    const setDataDirectoryObject = (str1: string, str2: string) => {
        const arrData = getDataDirectoryObject(db, str1, str2)
        onSetDirectory((prev) => {
            const newDirectory: IDirectory = {
                ...prev,
                [str1]: {
                    ...prev[str1],
                    [str2]: {
                        data: [...arrData],
                    }
                }
            }
            return newDirectory
        })
    }

    const handleSearch = (search: string) => {
        setSearchStr(search)
    }

    return (
        <div className="wrap">
            <Header text='Справочник КО' onBack="/"/>

            <div className="form-field">
                <InputSearch onSearch={handleSearch}/>
            </div>

            {(Object.keys(directoryObj).length === 0 && searched) ?
                <p>По вашему запросу ничего не найдено</p> :
                <WrapAccordion>
                    <FullAccordion header={'Единый перечень КО ЕАЭС'} expanded={searched}>
                        {Object.keys(directoryObj).map((grp1, idx) =>
                            <FullAccordion key={grp1} header={grp1} expanded={searched} onClick={() => {
                                !searched && setGRPD2DirectoryObject(grp1)
                            }}>
                                {Object.keys(directoryObj[grp1]).map((grp2, idx) =>
                                    <FullAccordion key={grp2} header={grp2} expanded={searched} onClick={() => {
                                        !searched && setDataDirectoryObject(grp1, grp2)
                                    }}>
                                        {directoryObj[grp1][grp2].data.map((data, idx) =>
                                            <FullAccordion key={data.id} header={data.KO_RUS + ' (' + data.KO_LAT + ')'}
                                                           style={{borderRadius: '0px', boxShadow: 'none'}}>
                                                <p>Документ по выявлению и идентификации КО: {data.DOC}</p>
                                                <LinkToFile db={db} id={data.id} title={'Справка'} fileName={'KO_FILE'} tableName={'EPKO'}/>
                                                <LinkToFile
                                                    db={db}
                                                    id={data.id}
                                                    title={'Правила обследований и установки КФЗ'}
                                                    fileName={'KO_SURVEY_DOC'}
                                                    tableName={'EPKO'}
                                                />
                                            </FullAccordion>
                                        )}
                                    </FullAccordion>
                                )}
                            </FullAccordion>
                        )}
                    </FullAccordion>
                </WrapAccordion>}
        </div>
    );
};

export default DirectoryPage;