import React, {useState} from 'react';
import {useSocket} from '../../hooks/useSocket';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {HubConnectionState} from "@microsoft/signalr";
import {IconButton, SxProps} from "@mui/material";
import {motion} from 'framer-motion';

import './ConnectionStateIndicator.scss'

const NOTIFICATION_ICON_STYLE_DISCONNECTED: SxProps = {
    width: '40px',
    height: '40px',
    color: '#d40404'
}

const NOTIFICATION_ICON_STYLE_CONNECTED: SxProps = {
    width: '40px',
    height: '40px',
    color: '#73C120'
}

const ConnectionStateIndicator = () => {

    const {state} = useSocket()
    const [expand, setExpand] = useState(false)

    const handleClickIcon = () => {
        setExpand(prev => !prev)
    }

    return (
        <div className="connection-indicator-wrapper">
            {state === HubConnectionState.Disconnected &&
                <>
                    <IconButton aria-label="delete" size="large" onClick={handleClickIcon}>
                        <ErrorIcon sx={NOTIFICATION_ICON_STYLE_DISCONNECTED}/>
                    </IconButton>
                    {expand && <motion.div
                        className="connection-indicator-text-wrapper"
                        initial={{x: '-20%'}}
                        animate={{x: '0'}}
                    >
                        <p style={{color: '#d40404'}}>Приложение работает в offline режиме</p>
                    </motion.div>}
                </>
            }
            {state === HubConnectionState.Connected &&
                <>
                    <IconButton aria-label="delete" size="large" onClick={handleClickIcon}>
                        <CheckCircleIcon sx={NOTIFICATION_ICON_STYLE_CONNECTED}/>
                    </IconButton>
                    {expand && <motion.div
                        className="connection-indicator-text-wrapper"
                        initial={{x: '-20%'}}
                        animate={{x: '0'}}
                    >
                        <p style={{color: '#73C120'}}>Приложение работает в online режиме</p>
                    </motion.div>}
                </>
            }
        </div>
    );
};

export default ConnectionStateIndicator;