import {createContext} from "react";
import {Database} from "sql.js";
interface IDataBaseContext {
    kfzDb: Database | null
    quarantDb: Database | null
    dataExportDb: Database | null
    notificationDb: Database | null
    loadKfzDb: () => void
    loadQuarantsDb: () => void
    loadDataExport: () => void
    error: any
}
export const DataBaseContext = createContext<IDataBaseContext>({} as IDataBaseContext)