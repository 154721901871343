import React, {FC, useEffect, useRef, useState} from 'react';
import {IAlertInformation} from "../../../interfaces/alert";
import {motion, PanInfo, useAnimation, Variants} from "framer-motion";
import {getNotificationButtonRightOffset, getNotificationButtonWidth, swipePower } from '../../../share/utils';

import './InformationAlert.scss'

interface InformationAlertProps {
    alert: IAlertInformation
}

const enum VariantTypes {
    TO_LEFT = 'toLeft',
    CENTER = 'center'
}

const variants: Variants = {
    [VariantTypes.TO_LEFT]: {
        opacity: 1,
        y: 0,
        x: "-50%"
    },
    [VariantTypes.CENTER]: {
        x: 0,
        opacity: 1,
        y: 0,
    }
};

const GAP = 10;
const InformationAlert: FC<InformationAlertProps> = ({alert}) => {

    const ref = useRef<HTMLDivElement>(null)
    const [rect, setRect] = useState<DOMRect | null>(null)
    const [currentAnimation, setCurrentAnimation] = useState<VariantTypes>(VariantTypes.CENTER)
    const [opacity, setOpacity] = useState(0)
    const [isDragging, setDragging] = useState(false);

    const animation = useAnimation();

    useEffect(() => {
        (async () => {if (ref.current) {
            setRect(ref.current.getBoundingClientRect());
            await animation.start('center')
        }})()
    }, []);

    const handleDragStart = () => {
        setDragging(true)
    }

    const handleDragEnd = async (event: PointerEvent, {offset}: PanInfo) => {
        if (!rect) return;
        const power = swipePower(offset.x, rect.width);
        if (power > 40) {
            setCurrentAnimation(VariantTypes.CENTER)
            setOpacity(0)
            await animation.start(VariantTypes.CENTER)
        }
        if (power < 40 && power > -40) {
            if (currentAnimation === VariantTypes.CENTER) {
                setOpacity(0)
            }
            if (currentAnimation === VariantTypes.TO_LEFT) {
                setOpacity(1)
            }
            await animation.start(currentAnimation)
        }
        if (power < -40) {
            setOpacity(1)
            setCurrentAnimation(VariantTypes.TO_LEFT)
            await animation.start(VariantTypes.TO_LEFT);
        }
        setDragging(false)
    }

    const handleDragOver = (event: PointerEvent, {offset}: PanInfo) => {
        if (!rect) return;
        const power = swipePower(offset.x, rect.width)
        if (power < 0) {
            setOpacity(power / 100 * -1)
        } else {
            setOpacity(1 - (power / 100))
        }
    }

    const handleOnClick = () => {
        if (isDragging) return;
        alert.onClick && alert.onClick(alert)
    }

    return (
        <div className="information-alert-wrapper-main">
            <motion.div
                ref={ref}
                className="information-alert-wrapper"
                initial={{opacity: 0, y: -100}}
                variants={variants}
                animate={animation}
                transition={{duration: 0.5, x: { type: "spring", mass: 0.5, stiffness: 500, damping: 50 }}}
                drag={"x"}
                dragConstraints={{ left: rect ? -rect.width : -430, right: 0 }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDrag={handleDragOver}
                dragMomentum={false}
                onClick={handleOnClick}
            >
                <div>{alert.title}</div>
                {/*<div>{alert.message}</div>*/}
            </motion.div>
            {
                <>
                    <div className="information-alert-button" style={{
                        width: rect ? getNotificationButtonWidth(rect.width, 2, GAP) + 'px' : '0',
                        right: rect ? getNotificationButtonRightOffset(getNotificationButtonWidth(rect.width, 2, GAP), 2, GAP) + 'px' : '0',
                        opacity: opacity
                    }} onClick={() => alert.onRead(alert)}>Прочитать</div>
                    <div className="information-alert-button" style={{
                        width: rect ? getNotificationButtonWidth(rect.width, 2, GAP) + 'px' : '0',
                        right: rect ? getNotificationButtonRightOffset(getNotificationButtonWidth(rect.width, 2, GAP), 1, GAP) + 'px' : '0',
                        opacity: opacity
                    }} onClick={() => alert.onDelete(alert)}>Удалить</div>
                </>
            }
        </div>
    );
};

export default InformationAlert;