import React from 'react';
import { useEffect } from 'react';
import { host } from '../environments/environments';

const LogoutPage = () => {

    useEffect(() => {
        
    }, [])


    return (<></>
    );
};

export default LogoutPage;