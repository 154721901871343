import React from 'react';

import './NSIPage.scss'
import Header from "../components/Header/Header";
import WrapAccordion from "../components/WrapAccordion/WrapAccordion";
import {useGetData} from "../hooks/useGetData";
import CountryAccordion from "../components/CountryAccordion/CountryAccordion";
import {host} from "../environments/environments";

export const HOST_DESKTOP = process.env.NODE_ENV === 'production' ? 'https://vniikr.ru' : 'https://vniikr.dev.asbc.lo'

interface ICountry {
    id: number
    name: string
}


const NsiPage = () => {

    const [countries, loading] = useGetData<Array<ICountry>>(host + `/mobile/nsi/country`)

    return (
        <div className="wrap">
            <Header text='Раздел НСИ' onBack="/" />
            <WrapAccordion>
                {countries?.map(country => <CountryAccordion countryId={country.id} header={country.name} />)}
            </WrapAccordion>
        </div>
    );
};

export default NsiPage;