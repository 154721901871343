import React, {FC, useEffect, useState} from 'react';
import Header from '../components/Header/Header';
import './SearchPage.scss'
import {StyledEngineProvider} from '@mui/material/styles';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useHistory} from "react-router-dom";
import ControlledAutocomplete from '../components/ControlledAutocomplete/ControlledAutocomplete'
import {
    SEARCH_COUNTRIES_QUERY,
    SEARCH_PP_QUERY,
    SEARCH_PP_QUERY_BY_TNVED,
    SEARCH_TNVED_BY_PP,
    SEARCH_TNVED_QUERY
} from "../constance/selectQuery";
import ActiveIcon from "../components/ActiveIcon/ActiveIcon";
import cameraIcon from '../img/camera.png';
import {Database} from "sql.js";
import {hasCodeTnvedFromDataExportByPP} from "../services/fetchData";
import {useDebounce} from "../hooks/useDebounce";
import Modal from "../components/Modal/Modal";
import {HELPERS} from "../constance/helps";


interface SearchPageProps {
    header: string
    labels: string[]
    onSearchTnved: (query: string) => (search: string) => void
    onSearchPP: (query: string) => (search: string) => void
    onSearchCountry: (query: string) => (search: string) => void
    searchTnvedCode: string[]
    searchPP: string[]
    searchCountry: string[]
    onSelectTnvedCode: () => void
    onSelectPP: () => void
    onSelectCountry: () => void
    onClearFlowerObject: () => void
    db: Database
    /*searchCntereExport: string[]*/
    /*searchRegeExport: string[]*/
    /*searchRegereExport: string[]*/
    /* searchRagneExport: string[]*/
    /* searchRagnreExport: string[]*/
    /*onSearchCntereExport: (str: string) => void*/
    /*onSearchRegeExport: (query: string) => (search: string) => void*/
    /* onSearchRegereExport: (query: string) => (search: string) => void*/
    /*onSearchRagneExport: (str: string) => void*/
    /*onSearchRagnreExport: (str: string) => void*/
    /*onSelectCntereExport: () => void*/
    /*onSelectRegeExport: () => void*/
    /*onSelectRegereExport: () => void*/
    /*onSelectRagneExport: () => void*/
    /* onSelectRagnreExport: () => void*/
}

type FormInputs = {
    codeTnved: string,
    PP: string,
    country: string,
    cntere_export: string,
    rege_export: string,
    regere_export: string,
    ragne_export: string,
    ragnre_export: string,
}

const SearchPage: FC<SearchPageProps> = ({
                                             header,
                                             labels,
                                             onSearchTnved,
                                             onSearchPP,
                                             onSearchCountry,
                                             onSelectTnvedCode,
                                             onSelectPP,
                                             onSelectCountry,
                                             searchTnvedCode,
                                             searchPP,
                                             searchCountry,
                                             onClearFlowerObject,
                                             db
                                         }) => {

    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [helperModalText, setHelperModalText] = useState('')

    const {handleSubmit, control, getValues, watch, setError, clearErrors} = useForm<FormInputs>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            codeTnved: '',
            country: '',
            PP: ''
        }
    })

    const watchPP = useDebounce(watch('PP'), 500)
    const watchTnved = useDebounce(watch('codeTnved'), 500)

    //очищаем объект растения, чтобы не хранился в памяти, когда перешли на страницу поиска
    useEffect(() => {
        onClearFlowerObject()
    }, [onClearFlowerObject])

    const goToImportObjectPage: SubmitHandler<FormInputs> = ({
                                                                 codeTnved,
                                                                 PP,
                                                                 country,
                                                                 cntere_export,
                                                                 rege_export,
                                                                 regere_export,
                                                                 ragne_export,
                                                                 ragnre_export
                                                             }) => {
        if (country && (codeTnved || PP)) {
            history.push(`${history.location.pathname}/object?`
                + `${codeTnved ? `tnved=${codeTnved}` : ''}`
                + `${PP ? `&pp=${PP}` : ''}`
                + `${country ? `&country=${country}` : ''}`
                + `${cntere_export ? `&cntere_export=${cntere_export}` : ''}`
                + `${rege_export ? `&rege_export=${rege_export}` : ''}`
                + `${regere_export ? `&regere_export=${regere_export}` : ''}`
                + `${ragne_export ? `&ragne_export=${ragne_export}` : ''}`
                + `${ragnre_export ? `&ragnre_export=${ragnre_export}` : ''}`)
        }
    }

    useEffect(() => {
        if (!watchTnved || searchTnvedCode.length !== 0) {
            clearErrors('codeTnved')
        }
        if (getValues('PP') && watchTnved.length > 2 && searchTnvedCode.length === 0) {
            setError('codeTnved', {
                message: 'Не соответствует подкарантинной продукции',
                type: 'DOES_NOT_MATCH'
            })
        }
    }, [watchTnved, getValues, searchTnvedCode.length, setError, clearErrors])


    useEffect(() => {
        if (!watchPP || searchPP.length !== 0) {
            clearErrors('PP')
        }
        if (getValues('codeTnved') && watchPP.length > 2 && searchPP.length === 0) {
            setError('PP', {
                message: 'Не соответствует коду ТН ВЭД',
                type: 'DOES_NOT_MATCH'
            })
        }
    }, [watchPP, getValues, searchPP.length, setError, clearErrors])

    const handleClickHelp = (helpText: string) => {
        setIsOpen(true)
        setHelperModalText(helpText)
    }

    const handleClickCloseHelperModal = () => {
        setIsOpen(false)
        setHelperModalText('')
    }

    return (
        <div className="wrap">
            <Header text={header} onBack="/"/>
            <form
                className="default-form"
                onSubmit={handleSubmit(goToImportObjectPage)}
            >
                <ActiveIcon onClick={() => {}} name={'camera'} icon={cameraIcon}/>
                <StyledEngineProvider injectFirst>
                    <ControlledAutocomplete
                        control={control}
                        nameInput={"codeTnved"}
                        onSearch={
                            getValues('PP') ?
                                onSearchTnved(SEARCH_TNVED_BY_PP(getValues('PP'))) :
                                onSearchTnved(SEARCH_TNVED_QUERY)
                        }
                        onSelect={onSelectTnvedCode}
                        options={searchTnvedCode}
                        title={"Код ТН ВЭД"}
                        transform={v => v.replace(/[^0-9]/g, "")}
                    />

                    <ControlledAutocomplete
                        control={control}
                        nameInput={"PP"}
                        //Оставил, если всё таки нужно будет выводить тнведы, если к данной пп не привязан тнвед
                        onSearch={getValues('codeTnved') ? onSearchPP(SEARCH_PP_QUERY_BY_TNVED(getValues('codeTnved'))) : onSearchPP(SEARCH_PP_QUERY)}
                        /*onSearch={onSearchPP(SEARCH_PP_QUERY_BY_TNVED(getValues('codeTnved')))}*/
                        onSelect={onSelectPP}
                        options={searchPP}
                        title={"Подкарантинная продукция"}
                        rules={{required: 'Поле не может быть пустым!'}}
                        voiceSearch
                        helpText={history.location.pathname.endsWith('import') ? HELPERS['pp_import'] : HELPERS['pp_export']}
                        onClickHelp={handleClickHelp}
                    />

                    <ControlledAutocomplete
                        control={control}
                        nameInput={"country"}
                        onSearch={onSearchCountry(SEARCH_COUNTRIES_QUERY)}
                        onSelect={onSelectCountry}
                        options={searchCountry}
                        title={labels[0]}
                        rules={{required: 'Поле не может быть пустым!'}}
                        voiceSearch
                        helpText={history.location.pathname.endsWith('import') ? HELPERS['country_import'] : HELPERS['country_export']}
                        onClickHelp={handleClickHelp}
                    />

                </StyledEngineProvider>
                <button className="green-button" style={{marginBottom: '10px'}} type="submit">Найти</button>
            </form>
            <Modal isOpen={isOpen} onClose={handleClickCloseHelperModal}>
                <p className="notification-message">{helperModalText}</p>
            </Modal>
        </div>
    );
};

export default SearchPage;