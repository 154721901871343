import React, {Dispatch, FC, SetStateAction, useCallback, useEffect, useState} from 'react'
import UpdatePage from './pages/UpdatePage'
import SearchPage from './pages/SearchPage'
import DirectionPage from './pages/DirectionPage'
import LogoutPage from './pages/logout'
import DirectoryPage from './pages/DirectoryPage'
import ObjectPage from './pages/ObjectPage'
import OfflinePage from './pages/OfflinePage'
import ErrorPage from './pages/error'
import {Route} from 'react-router-dom'
import PrivateRoute from './routes/PrivateRoute'
import './App.scss'
import localforage from 'localforage'
import {FileNames, IDirectory, IKFZ, IObject, ISearchObject} from './interfaces/interfaces'
import {getObj, getSearchData} from './services/fetchData'
import Auth from './services/auth'
import KfzDirectory from './pages/KfzDirectory'
import {findItemsInQueryResult} from "./services/transformData";
import {useLogs} from "./hooks/useLogs";
import PDFViewerPage from "./pages/PDFViewerPage";
import SupportPage from "./pages/SupportPage";
import initSqlJs, {Database} from 'sql.js'
import {useDataBase} from "./hooks/useDataBase";
import AlertWrapper from "./components/AlertWrapper/AlertWrapper";
import NotificationPage from "./pages/NotificationPage";
import ConnectionStateIndicator from "./components/ConnectionStateIndicator/ConnectionStateIndicator";
import NsiPage from "./pages/NSIPage";

const App: FC = () => {

    const {kfzDb, quarantDb, dataExportDb, error} = useDataBase()

    const [noConnectToNetwork, setNoConnectToNetwork] = useState<any>(false)
    const [searchTnvedCode, setSearchTnvedCode] = useState<string[]>([])
    const [searchPP, setSearchPP] = useState<string[]>([])
    const [searchCountry, setSearchCountry] = useState<string[]>([])
    const [flowerObject, setFlowerObject] = useState<IObject[]>([])
    const [directoryObject, setDirectoryObject] = useState<IDirectory>({} as IDirectory)
    const [kfz, setKfz] = useState<IKFZ>({
        regions: []
    })

    useLogs({fetchLogs: true, domlogs: true})

    useEffect(() => {
        Auth.getUser()
            .then(() => {
                setNoConnectToNetwork('')
            })
            .catch(() => {
                setNoConnectToNetwork("Отсутствует подключение к интернету, вы работаете в офлайн режиме")
            })
    }, [])

    /*    useEffect(() => {
            if (!dataExportDb || levenshtainDictionaryPP.length !== 0 || levenshtainDictionaryCountries.length !== 0) return;
            setLevenshtainDictionaryPP(getLevenshtainDictionary(dataExportDb, SEARCH_PP_QUERY))
            setLevenshtainDictionaryCountries(getLevenshtainDictionary(dataExportDb, SEARCH_COUNTRIES_QUERY))
        }, [dataExportDb, levenshtainDictionaryPP.length, levenshtainDictionaryCountries.length])*/

    //очистка всех объектов
    const handleClearFlowerObject = useCallback(() => {
        setFlowerObject([])
    }, [])

    //получение данных для подстановки в поисковую строку
    const handleGetItemsForSearch = (setFunc: React.Dispatch<React.SetStateAction<string[]>>, dictionary: string[] = [], method: 'startWith' | 'includes' = 'includes') =>
        (query: string) =>
            (search: string) => {
                if (error) return
                if (search === '' || search.split('').length < 3) return setFunc([])
                let items = getSearchData(dataExportDb as Database, query)
                if (items.length === 0) return
                let result: string[] = findItemsInQueryResult(search, items, dictionary, method)
                setFunc(result.slice(0, 5))
            }

    const handleGetObjectFlowerNorm = useCallback((obj: ISearchObject) => {
        const objFlower = getObj(dataExportDb as Database, obj)
        setFlowerObject(objFlower)
    }, [dataExportDb])

    return (
        <div className="container">
            <AlertWrapper />
            <Route path="/update">
                <PrivateRoute>
                    <UpdatePage />
                </PrivateRoute>
            </Route>
            <Route path="/" exact>
                <DirectionPage error={error} noConnectToNetwork={noConnectToNetwork}/>
            </Route>
            <Route path="/import" exact>
                <SearchPage
                    header="Импорт"
                    labels={["Страна экспорта"]}
                    onSearchTnved={handleGetItemsForSearch(setSearchTnvedCode, [], 'startWith')}
                    onSearchPP={handleGetItemsForSearch(setSearchPP, [], 'startWith')}
                    onSearchCountry={handleGetItemsForSearch(setSearchCountry, [], 'startWith')}
                    onSelectTnvedCode={() => setSearchTnvedCode([])}
                    onSelectPP={() => setSearchPP([])}
                    onSelectCountry={() => setSearchCountry([])}
                    searchTnvedCode={searchTnvedCode}
                    searchPP={searchPP}
                    searchCountry={searchCountry}
                    onClearFlowerObject={handleClearFlowerObject}
                    db={dataExportDb as Database}
                />
            </Route>
            <Route path='/import/object'>
                <ObjectPage
                    header="Импорт"
                    onGetObjectFlowerNorm={handleGetObjectFlowerNorm}
                    flowerObject={flowerObject}
                    db={quarantDb as Database}
                />
            </Route>
            <Route path="/export" exact>
                <SearchPage
                    header="Экспорт"
                    labels={["Страна импорта"]}
                    onSearchTnved={handleGetItemsForSearch(setSearchTnvedCode, [], 'startWith')}
                    onSearchPP={handleGetItemsForSearch(setSearchPP, [], 'startWith')}
                    onSearchCountry={handleGetItemsForSearch(setSearchCountry, [], 'startWith')}
                    onSelectTnvedCode={() => setSearchTnvedCode([])}
                    onSelectPP={() => setSearchPP([])}
                    onSelectCountry={() => setSearchCountry([])}
                    searchTnvedCode={searchTnvedCode}
                    searchPP={searchPP}
                    searchCountry={searchCountry}
                    onClearFlowerObject={handleClearFlowerObject}
                    db={dataExportDb as Database}
                />
            </Route>
            <Route path='/export/object'>
                <ObjectPage
                    header="Экспорт"
                    onGetObjectFlowerNorm={handleGetObjectFlowerNorm}
                    flowerObject={flowerObject}
                    db={quarantDb as Database}
                />
            </Route>
            <Route path="/logout" exact component={LogoutPage}/>
            <Route path='/directory'>
                <DirectoryPage onSetDirectory={setDirectoryObject} db={quarantDb as Database}
                               directoryObj={directoryObject as IDirectory}/>
            </Route>
            <Route path='/kfz'>
                <KfzDirectory db={kfzDb as Database} onSetKfz={setKfz} kfzObj={kfz}/>
            </Route>
            <Route path='/offline' component={OfflinePage}/>
            <Route path='/error' component={ErrorPage}/>
            <Route path='/nsi' component={NsiPage}/>
            <Route path='/file'>
                <PDFViewerPage db={quarantDb}/>
            </Route>
            <Route path='/support' component={SupportPage} />
            <Route path='/notifications'>
                <NotificationPage/>
            </Route>
        </div>
    );
};
export default App;
