import React, {FC, useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckMark from '../../img/check-mark.png';
import ErrorMark from '../../img/error.png';
import UploadMark from '../../img/upload-mark.png';
import {FileNames} from "../../interfaces/interfaces";
import {PropsForUpdateItem, StateItemTypes, withStatusDeterminant} from "../../hoc/StatusDeterminant";
import {host} from "../../environments/environments";
import localforage from "localforage";
import {mergedUint8Arrays} from "../../share/utils";

import './UpdateItem.scss';

export interface UpdateItemProps {
    children: React.ReactNode | React.ReactNode[]
    alignItems?: 'flex-end' | 'flex-start' | 'center'
    fileName: FileNames
    bodyRequest?: { [key: string]: number[] | string }
    checkUpdate: () => void
    onDownload: () => void
    replacingText?: string
    additionalButtons?: React.ReactNode
}

const UpdateItem: FC<UpdateItemProps & PropsForUpdateItem> = ({
                                                                  children,
                                                                  alignItems = 'center',
                                                                  fileName,
                                                                  bodyRequest,
                                                                  checkUpdate,
                                                                  onDownload,
                                                                  status,
                                                                  replacingText,
                                                                  additionalButtons
                                                              }) => {

    const [stateItem, setStateItem] = useState<StateItemTypes | null>(status)
    const [progress, setProgress] = useState(0)

    const renderChildren = (function () {
        if (stateItem === StateItemTypes.LOADED) {
            if (!replacingText) return children;
            return <p className='update-label'>{replacingText}</p>;
        }
        return children
    })()

    useEffect(() => {
        setStateItem(status)
    }, [status])

    const handleDownloadClick = async (fileName: FileNames, checkUpdate: () => void, body?: { [key: string]: number[] | string }) => {

        setStateItem(StateItemTypes.DOWNLOADING)
        if (stateItem === StateItemTypes.EMPTY || stateItem === StateItemTypes.NEED_UPDATE) {

            const chunks: Uint8Array[] = []
            const response = await fetch(`${host}/mobile/download/${fileName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Current-position': '0'
                },
                body: JSON.stringify(body)
            })

            if (response.status !== 200) {
                console.error('Не удалось скачать файл')
            }

            if (response.headers && response.body) {
                setStateItem(StateItemTypes.DOWNLOADING)
                let receivedLength = 0

                const reader = response.body.getReader();

                const hash = response.headers.get('Hash')
                const allLength = response.headers.get('Content-Length')

                await localforage.setItem(fileName + '_hash', hash)
                await localforage.setItem(fileName + '_length', allLength)


                try {
                    while (true) {
                        const {done, value} = await reader.read()
                        if (done) {
                            break;
                        }
                        if (value) {
                            const expectedLength = allLength
                            chunks.push(value)
                            receivedLength += value.length
                            if (expectedLength) {
                                let percentLength = Math.floor((receivedLength / +expectedLength) * 100)
                                setProgress(percentLength)
                            }
                        }
                    }
                } catch (err) {
                    setStateItem(StateItemTypes.EMPTY)
                }
            }

            const installedFile = await localforage.setItem(fileName + '_db', mergedUint8Arrays(chunks))
            const controlLength = await localforage.getItem<string | null>(fileName + '_length')
            await localforage.setItem(fileName + '_current_length', installedFile?.byteLength.toString())

            if (controlLength && installedFile.byteLength === +controlLength) {
                setStateItem(StateItemTypes.LOADED)
                //Если всё скачалось, выполняем загрузку файла в sql-wasm
                onDownload()
            }
            checkUpdate()
        }

        if (stateItem === StateItemTypes.INCOMPLETE) {
            const chunks: Uint8Array[] = []
            const currentLength = await localforage.getItem<string | null>(fileName + '_current_length')
            const controlLength = await localforage.getItem<string | null>(fileName + '_length')
            const response = await fetch(`${host}/mobile/download/${fileName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Current-position': currentLength ?? '0'
                },
                body: JSON.stringify(body)
            })

            if (response.status !== 200) {
                console.error('Не удалось скачать файл')
            }

            if (response.headers && response.body) {
                setStateItem(StateItemTypes.DOWNLOADING)
                let receivedLength = currentLength ? +currentLength : 0

                const reader = response.body.getReader();

                try {
                    while (true) {
                        const {done, value} = await reader.read()
                        if (done) {
                            break;
                        }
                        if (value) {
                            const expectedLength = controlLength ? controlLength : 100000000
                            chunks.push(value)
                            receivedLength += value.length
                            if (expectedLength) {
                                let percentLength = Math.floor((receivedLength / +expectedLength) * 100)
                                setProgress(percentLength)
                            }
                        }
                    }
                } catch (err) {
                    setStateItem(StateItemTypes.EMPTY)
                }
            }

            const file = await localforage.getItem<Uint8Array>(fileName + '_db')
            if (file) {
                chunks.unshift(file)
            }

            const installedFile = await localforage.setItem(fileName + '_db', mergedUint8Arrays(chunks))
            await localforage.setItem(fileName + '_current_length', installedFile?.byteLength.toString())

            if (controlLength && installedFile.byteLength === +controlLength) {
                setStateItem(StateItemTypes.LOADED)
                //Если всё скачалось, выполняем загрузку файла в sql-wasm
                onDownload()
            }
            checkUpdate()
        }
    }

    const handleDeleteClick = async () => {
       await localforage.removeItem(fileName + '_db')
       await localforage.removeItem(fileName + '_hash')
       await localforage.removeItem(fileName + '_length')
        window.location.reload()
    }

    if (status === null) {
        return null
    }

    return (
        <div className={'update-item'} style={{alignItems}}>
            {(stateItem === StateItemTypes.EMPTY || stateItem === StateItemTypes.INCOMPLETE) &&
                <img src={ErrorMark} alt='Данные отсутствуют'/>}
            {stateItem === StateItemTypes.NEED_UPDATE && <img src={UploadMark} alt='Необходимо обновление'/>}
            {stateItem === StateItemTypes.LOADED && <img src={CheckMark} alt='Данные актуальны'/>}
            {stateItem === StateItemTypes.DOWNLOADING &&
                <CircularProgress size={30} sx={{color: '#73c120'}} value={progress} variant="determinate"/>}
            {renderChildren}
            {stateItem !== StateItemTypes.LOADED &&
                <div className='update-item-buttons-wrapper'>
                    <button className={stateItem === StateItemTypes.DOWNLOADING ? ' disabled' : ''}
                             onClick={() => handleDownloadClick(fileName, checkUpdate, bodyRequest)}>
                        {(stateItem === StateItemTypes.EMPTY || stateItem === StateItemTypes.INCOMPLETE) && 'Загрузить'}
                        {stateItem === StateItemTypes.NEED_UPDATE && 'Обновить'}
                        {stateItem === StateItemTypes.DOWNLOADING && 'Обновить'}
                    </button>
                    {additionalButtons}
                </div>
            }
            {stateItem === StateItemTypes.LOADED &&
               <div className='update-item-buttons-wrapper'>
                   <button className={'delete-btn'} onClick={handleDeleteClick}>Удалить</button>
               </div>
            }
        </div>
    );
};

export default UpdateItem;

export const UpdateItemsWithDeterminateStatus = withStatusDeterminant(UpdateItem)