import React, {FC, useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "../../share/accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import './FullAccordion.scss'

export interface FullAccordionProps {
    style?: React.CSSProperties
    header: React.ReactNode | React.ReactNode[]
    children?: React.ReactNode | React.ReactNode[]
    onClick?: () => void
    expanded?: boolean
    headerClassName?: string
}

const FullAccordion: FC<FullAccordionProps> = ({style, header, children, expanded= false, headerClassName, onClick}) => {

    const [expd, setExpanded] = useState(expanded)

    useEffect(() => {
        setExpanded(expanded)
    }, [expanded])

    return (
        <Accordion style={style} expanded={expd}>
            <AccordionSummary
                className={headerClassName}
                expandIcon={<ExpandMoreIcon />}
                onClick={() => {
                    onClick && onClick()
                    setExpanded(prev => !prev)
                }}
            >
                {header}
            </AccordionSummary>
            <AccordionDetails>
                <div className="details-wrapper">
                    {children}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default FullAccordion;