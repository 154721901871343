import {Database, QueryExecResult} from "sql.js";
import {
    IDistrictKfz,
    IKFZ,
    IObject,
    IQuarantObj,
    ISearchObject,
    Uint8ArrayFileWithName
} from "../interfaces/interfaces";
import {
    transformDataDirectoryObject,
    transformDisctrictKfz,
    transformFile, transformFileId,
    transformGRP1DirectoryObject,
    transformGRP2DirectoryObject, transformIsFileEmpty, transformNotification, transformNotifications,
    transformObj,
    transformQuarantObj, transformQuarantObjByCntr, transformQuarantObjByCntrReference,
    transformQuarantObjByPPAndCntr,
    transformQuarantObjKfz,
    transformRegionsKfz,
    transformSearch, transformToId, transformToIds
} from '../services/transformData'
import {HAS_CODETNVED_BY_UQUARANTPROD, SELECT_NOTIFICATIONS_FROM_WEEDS} from "../constance/selectQuery";
import {INotification} from "../interfaces/notification";
import {
    DELETE_LAST_NOTIFICATION_FROM_NOTIFICATION,
    DELETE_LAST_NOTIFICATIONS_FROM_NOTIFICATION
} from "../constance/deleteQuery";
import {INSERT_NOTIFICATION_INTO_NOTIFICATION} from "../constance/insertQuery";
import {UPDATE_READ_NOTIFICATION_INTO_NOTIFICATIONS} from "../constance/updateQuery";

export function getSearchData(db: Database, query: string): string[] {
    try {
        const res = db.exec(query)
        return transformSearch(res as QueryExecResult[])
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getObj(db: Database,
                       {
                           direction,
                           tnved,
                           pp,
                           country,
                           cntere_export,
                           ragne_export,
                           ragnre_export,
                           rege_export,
                           regere_export
                       }: ISearchObject): IObject[] {
    try {
        const query = `select ct.cntr, ${rege_export ? "e.name, e.name_r, " : 'regexport, regexport_r, '}  ${ragne_export ? "b.name, b.name_r, " : 'agnexport, agnexport_r, '}
        ${regere_export ? "f.name, f.name_r, " : 'regreexport, regreexport_r, '} ${ragnre_export ? "c.name, c.name_r, " : "agnreexport, agnreexport_r, "} ${cntere_export ? "a.name, a.name_r, " : "cntrreexport, cntrreexport_r,"}  comp, comp_r,
        uquarantprod_r, uquarantprod_l, doc, doc_r, ${tnved ? 't.name, t.name_r,' : 'codetnved, codetnved_r,'}
        quarantobj, quarantobj_r, uquarantobj, uquarantobj_r, oth, oth_r, plcimp, plcimp_r, pack, pack_r, transit, transit_r,
        transcond, transcond_r, smpregpr 
        from DATA_EXPORT d, CNTR_EXP ct `
            + `${tnved ? ', TNVED_EXPORT t ' : ''}`
            + `${cntere_export ? ', cntere_export a ' : ''}`
            + `${ragne_export ? ', ragne_export b ' : ''}`
            + `${ragnre_export ? ', ragnre_export c ' : ''}`
            + `${rege_export ? ', rege_export e ' : ''}`
            + `${regere_export ? ', regere_export f ' : ''}`
            + `WHERE ${tnved ? `t.CODE = '${tnved}' AND t.PID = d.ID AND ` : ''}`
            + `${cntere_export ? `a.code = '${cntere_export}' AND a.PID = d.ID AND ` : ''}`
            + `${ragne_export ? `b.code = '${ragne_export}' AND b.PID = d.ID AND ` : ''}`
            + `${ragnre_export ? `c.code = '${ragnre_export}' AND c.PID = d.ID AND ` : ''}`
            + `${rege_export ? `e.code = '${rege_export}' AND e.PID = d.ID AND ` : ''}`
            + `${regere_export ? `f.code = '${regere_export}' AND f.PID = d.ID AND ` : ''}`
            + `${pp ? `(d.UQUARANTPROD_L = '${pp}' OR d.UQUARANTPROD_R = '${pp}') ` : ''}`
            + `${(tnved || pp) && country ? 'AND ' : ''} ${country ? `ct.CNTR = '${country}'` : ''}`
            + ` AND ie = ${direction}`
            + ` AND ct.rnumb = d.cntr`


        //Получение карантинных объектов в разрезе подкарантинной продукции и страны
        const query_get_quarantobj_cnt = `select oe.quarantobjcnt
        from DATA_EXPORT d, CNTR_EXP ct, OBJCNT_EXP oe `
            + `${tnved ? ', TNVED_EXPORT t ' : ''}`
            + `WHERE ${tnved ? `t.CODE = '${tnved}' AND t.PID = d.ID AND ` : ''}`
            + `${country ? `ct.CNTR = '${country}' AND ` : ''}`
            + `${pp ? `(d.UQUARANTPROD_L = '${pp}' OR d.UQUARANTPROD_R = '${pp}') ` : ''}`
            + ` AND ie = ${direction}`
            + ` AND ct.rnumb = d.cntr`
            + ` AND ct.id = oe.cntr_id AND oe.data_id = d.id`


        //Получение карантинных объектов в разрезе страны из требования
        let query_get_quarantobj = ''
        if (direction === 0) {
            query_get_quarantobj = `select qe.quarantobj, qe.quarantobj_r
            from CNTR_EXP ct, QUARANTOBJ_EXP qe ` + `WHERE ct.CNTR = 'РОССИЯ' AND ct.rnumb = qe.cntr`
        } else {
            query_get_quarantobj = `select qe.quarantobj, qe.quarantobj_r
            from CNTR_EXP ct, QUARANTOBJ_EXP qe ` + `WHERE ${country ? `ct.CNTR = '${country}' AND ct.rnumb = qe.cntr` : ''}`
        }

        //Получение всех карантинных объектов в разрезе страны из справочника
        let query_get_quarantobj_by_cntr_reference = ''
        if (direction === 0) {
            query_get_quarantobj_by_cntr_reference = `select quarantobj from CNTRQOBJ_EXP WHERE cntr = '${country}'`
        } else {
            query_get_quarantobj_by_cntr_reference = `select quarantobj from CNTRQOBJ_EXP WHERE cntr = 'РОССИЯ'`
        }

        const res = db.exec(query)
        const resQuarantObjByPPAndCNTR = db.exec(query_get_quarantobj_cnt)
        const resQuarantObjByCNTR = db.exec(query_get_quarantobj)
        const resQuarantObjByCNTRReference = db.exec(query_get_quarantobj_by_cntr_reference)

        const objsWithoutQuarantObjCnt = transformObj(res)
        const quarantObjByPPAndCnt = transformQuarantObjByPPAndCntr(resQuarantObjByPPAndCNTR)
        const quarantObjByCntr = transformQuarantObjByCntr(resQuarantObjByCNTR)
        const quarantObjByCntrReference = transformQuarantObjByCntrReference(resQuarantObjByCNTRReference)

        //Добавляем карантинные объекты по стране в разрезе пп или все ко по стране (если экспорт), данные из требований
        let fullObjs = objsWithoutQuarantObjCnt.length !== 0 ? objsWithoutQuarantObjCnt.map((objWithoutQuarantObjCnt) => ({
            ...objWithoutQuarantObjCnt,
            'Подкарантинная продукция': {
                ...objWithoutQuarantObjCnt['Подкарантинная продукция'],
                'Карантинные_объекты_страна': quarantObjByPPAndCnt
            },
            ...quarantObjByCntr
        })) : [{
            country: country || '',
            ...quarantObjByCntr
        }]

        //Добавляем КО по стране из справочника
        fullObjs = (quarantObjByCntrReference?.Карантинные_объекты && quarantObjByCntrReference?.Карантинные_объекты.length !== 0) ? fullObjs.map(obj => ({
            ...obj,
            'КО_страны': quarantObjByCntrReference
        })) : fullObjs

        return fullObjs
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getGRP1DirectoryObject(db: Database) {
    try {
        const res = db.exec('SELECT GRP1 FROM EPKO GROUP BY GRP1')
        return transformGRP1DirectoryObject(res)
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getGRP2DirectoryObject(db: Database, grp1: string) {
    try {
        const res = db.exec(`SELECT GRP2 FROM EPKO WHERE GRP1 = '${grp1}' GROUP BY GRP2`)
        return transformGRP2DirectoryObject(res)
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getDataDirectoryObject(db: Database, grp1: string, grp2: string) {
    try {
        const res = db.exec(`SELECT ID, KO_LAT, KO_RUS, DOC FROM EPKO WHERE GRP1 = '${grp1}' AND GRP2 = '${grp2}'`)
        return transformDataDirectoryObject(res)
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getQuarantObjByName(db: Database, name: string) {
    try {
        const res = db.exec(`SELECT KO_LAT, KO_RUS, DOC, KO_FILE, KO_SURVEY_DOC, GRP1, GRP2, ID FROM EPKO`)
        const lowerName = name.toLowerCase()
        const filteredRes = [{
            columns: res[0].columns,
            values: res[0].values.filter(row => row[0]?.toString().toLowerCase().startsWith(lowerName) || row[1]?.toString().toLowerCase().startsWith(lowerName))
        }]
        return transformQuarantObj(filteredRes)
    } catch (err) {
        console.error(err)
        return []
    }
}

export function getFile(fileName: string, tableName: string, db: Database, id: string): Uint8ArrayFileWithName | null {
    try {
        const res = db.exec(`SELECT ${fileName}, KO_RUS, KO_LAT FROM ${tableName} WHERE ID = ${id}`)
        return transformFile(res)
    } catch (err) {
        console.log(err)
        return null
    }
}

export function getIdByLatName(tableName: string, db: Database, latName: string) {
    try {
        const res = db.exec(`SELECT ID FROM ${tableName} WHERE KO_LAT = '${latName}'`)
        return transformFileId(res)
    } catch (err) {
        console.log(err)
        return null
    }
}

export function isFileNotEmptyByLatName(fileName: string, tableName: string, db: Database, latName: string): boolean {
    try {
        const res = db.exec(`SELECT COUNT(${fileName}) FROM ${tableName} WHERE KO_LAT = '${latName}' AND LENGTH(${fileName}) > 0`)
        return transformIsFileEmpty(res)
    } catch (err) {
        console.log(err)
        return false
    }
}

export function isFileNotEmpty(fileName: string, tableName: string, db: Database, id: string): boolean {
    try {
        const res = db.exec(`SELECT COUNT(${fileName}) FROM ${tableName} WHERE ID = ${id} AND LENGTH(${fileName}) > 0`)
        return transformIsFileEmpty(res)
    } catch (err) {
        console.log(err)
        return false
    }
}

export function getFileByLatName(fileName: string, tableName: string) {
    return function (db: Database, latName: string) {
        try {
            const res = db.exec(`SELECT ${fileName} FROM ${tableName} WHERE KO_LAT LIKE '${latName}'`)
            return transformFile(res)
        } catch (err) {
            console.log(err)
            return null
        }
    }
}

export function getRegions(db: Database): IKFZ {
    try {
        const res = db.exec(`SELECT region FROM kfz_list GROUP BY region`)
        return transformRegionsKfz(res)
    } catch (err) {
        console.log(err)
        return {
            regions: []
        }
    }
}

export function getQuarantObj(db: Database, region: string): IQuarantObj[] {
    try {
        const res = db.exec(`SELECT qobj_rus FROM kfz_list WHERE region == '${region}' GROUP BY qobj_rus`)
        return transformQuarantObjKfz(res)
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getDistrictKfz(db: Database, regionName: string, quarantObjName: string): IDistrictKfz[] {
    try {
        const res = db.exec(`SELECT district, area, quant, munits FROM kfz_list WHERE qobj_rus == '${quarantObjName}' AND region == '${regionName}'`)
        return transformDisctrictKfz(res)
    } catch (err) {
        console.log(err)
        return []
    }
}

export function hasCodeTnvedFromDataExportByPP(db: Database, pp: string): boolean {
    try {
        const res = db.exec(HAS_CODETNVED_BY_UQUARANTPROD(pp))
        return res.length !== 0
    } catch (err) {
        console.log(err)
        return false
    }
}

export function getLevenshtainDictionary(db: Database, query: string): string[] {
    try {
        const res = db.exec(query)
        const ppArray = transformSearch(res)
        let levenshtainArray: string[] = []
        if (res.length !== 0) {
            ppArray.forEach(ppStr => {
                const splitArray = ppStr.split(' ')
                levenshtainArray = levenshtainArray.concat(splitArray)
            })
            levenshtainArray = levenshtainArray.concat(ppArray)
        }
        return levenshtainArray.map(word => word.toLowerCase())
    } catch (err) {
        console.log(err)
        return []
    }
}

export function getNotifications(db: Database): INotification[] {
    try {
        const res = db.exec(SELECT_NOTIFICATIONS_FROM_WEEDS)
        return transformNotifications(res)
    } catch(err) {
        console.log(err)
        return []
    }
}

export function deleteNotification(db: Database, limit: number): null | number[] {
    try {
        const res = db.exec(DELETE_LAST_NOTIFICATIONS_FROM_NOTIFICATION(limit))
        return transformToIds(res)
    } catch (err) {
        console.error(err)
        return null
    }
}

export function addNotificationToDb(db: Database, newNotification: INotification): null | INotification {
    try {
        const res = db.exec(INSERT_NOTIFICATION_INTO_NOTIFICATION(newNotification))
        return transformNotification(res)
    } catch(err) {
        console.error(err)
        return null
    }
}

export function readNotificationInDb(db: Database, notificationId: string): null | number {
    try {
        const res = db.exec(UPDATE_READ_NOTIFICATION_INTO_NOTIFICATIONS(notificationId))
        return transformToId(res)
    } catch(err) {
        console.error(err)
        return null
    }
}