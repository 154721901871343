import {useInterceptor} from "./useInterceptor";
import {useDomLogs} from "./useDomLogs";

interface useLogsProps {
    fetchLogs: boolean,
    domlogs: boolean
}

export const useLogs = ({fetchLogs, domlogs}:useLogsProps) => {
    useInterceptor(fetchLogs)
    useDomLogs(domlogs)
}