import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Popper from "@mui/material/Popper";
import {autocompleteClasses} from '@mui/material/Autocomplete';

export const CssTextField = styled(TextField)({
    '&.MuiAutocomplete-inputRoot': {
        paddingRight: '0 !important'
    },
    '&.error': {
        '& .MuiOutlinedInput-root': {
            border: '1px solid red'
        }
    },
    '& label.Mui-focused': {
        color: '#73c120',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        minHeight: '50px',
        width: '200px',
        color: 'black',
        padding: '1px 5px',
        '& fieldset': {
            border: '1px solid transparent',
            boxShadow: '0px 4px 7px rgb(0 0 0 / 25%)',
            backgroundColor: 'white',
        },
        '&:hover fieldset': {
            border: '1px solid transparent',
            outline: 'none',
        },
        '&.Mui-focused fieldset': {
            outline: 'none',
            border: '1px solid #73c120'
        },
        '& .MuiOutlinedInput-input': {
            border: '1px solid transparent',
            boxShadow: 'none',
            backgroundColor: 'white',
            zIndex: '100',
            height: '30px'
        },
        '& input::placeholder': {
            color: 'grey',
            opacity: '1',
            fontFamily: 'TTNormRegular, sans-serif',
            fontSize: '18px'
        },
        '& .MuiButtonBase-root': {
            zIndex: '110'
        },
        '& .MuiChip-root.MuiChip-filled': {
            backgroundColor: '#73c120',
            color: 'white'
        }
    },
    '@media(min-width: 700px)': {
        '& .MuiOutlinedInput-root': {
            width: '250px',
        }
    },
})

export const StyledPopper = styled(Popper)({
    borderRadius: '0px',
    padding: 0,
    '& .MuiPaper-root': {
        maxHeight: '300px',
    },
    '& ul': {
        maxHeight: '300px',
    },
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        borderRadius: '0px',
        '& ul': {
            padding: 0,
            margin: 0,
            borderRadius: '0px'
        },
        '& li': {
            border: '1px solid transparent',
            padding: 0,
            borderRadius: '0px'
        },
        '& li:last-child': {
            borderRadius: '0px'
        },
        '& li[aria-selected=true]': {
            backgroundColor: '#aef193'
        }
    },
});