import React, {ComponentType, FC, useEffect, useState} from 'react';
import {UpdateItemProps} from "../components/UpdateItem/UpdateItem";
import localforage from "localforage";

export interface StatusDeterminantProps {
    needUpdate: boolean
}

export enum StateItemTypes {
    INCOMPLETE = 'INCOMPLETE',
    DOWNLOADING = 'DOWNLOADING',
    EMPTY = 'EMPTY',
    NEED_UPDATE = 'NEED_UPDATE',
    LOADED = 'LOADED'
}

export interface PropsForUpdateItem {
    status: StateItemTypes | null
}

export function withStatusDeterminant(UpdateItemComponent: ComponentType<PropsForUpdateItem & UpdateItemProps>): FC<StatusDeterminantProps & UpdateItemProps> {

    const WrapperComponent: FC<StatusDeterminantProps & UpdateItemProps> = (props) => {

        const [status, setStatus] = useState<StateItemTypes | null>(null)
        let {needUpdate, ...otherProps} = props

        useEffect(() => {
            (async function () {
                let state: StateItemTypes = StateItemTypes.EMPTY
                const allLength = await localforage.getItem<number | null>(otherProps.fileName + '_length')
                const currentLength = await localforage.getItem(otherProps.fileName + '_current_length')

                if (currentLength && allLength) {
                    if (needUpdate) {
                        console.log('need update', otherProps.fileName)
                        state = StateItemTypes.NEED_UPDATE
                    } else {
                        if (+currentLength === +allLength) {
                            state = StateItemTypes.LOADED
                        } else {
                            state = StateItemTypes.INCOMPLETE
                        }
                    }
                }

                setStatus(state)
            })()
        }, [otherProps.fileName, needUpdate])

        return status && <UpdateItemComponent status={status} {...otherProps}/>
    }

    return WrapperComponent
};

