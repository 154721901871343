import React, {FC, useEffect, useState} from 'react';
import {Database} from "sql.js";
import {useHistory} from "react-router-dom";
import {isFileNotEmpty} from "../../services/fetchData";

interface LinkToFileProps {
    db: Database,
    id: string,
    title: string,
    fileName: string,
    tableName: string
}

const LinkToFile: FC<LinkToFileProps> = ({db, id,title, fileName, tableName}) => {

    const history = useHistory()
    const [isNotEmpty, setIsNotEmpty] = useState(false)

    const goToFileView = () => {
        history.push(`/file?id=${id}&fileName=${fileName}&tableName=${tableName}`)
    }

    useEffect(() => {
        setIsNotEmpty(isFileNotEmpty(fileName, tableName, db, id))
    }, [fileName, tableName, db, id])

    return (
        <>
            {
                isNotEmpty &&
                <p
                    className="link"
                    onClick={goToFileView}
                > {title}</p>
            }
        </>
    )
};

export default LinkToFile;