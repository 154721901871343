export const SEARCH_TNVED_QUERY = `SELECT code FROM tnved_export GROUP BY code`
export const SEARCH_PP_QUERY = `select UQUARANTPROD from (select UQUARANTPROD_L UQUARANTPROD from DATA_EXPORT where UQUARANTPROD_L is not null
      union ALL
      select UQUARANTPROD_R from DATA_EXPORT where UQUARANTPROD_R is not null) GROUP BY UQUARANTPROD`
export const SEARCH_COUNTRIES_QUERY = `SELECT CNTR FROM CNTR_EXP GROUP BY CNTR`
export const SEARCH_PP_QUERY_BY_TNVED = (tnved: string) =>
     `select UQUARANTPROD from (select UQUARANTPROD_L UQUARANTPROD, id from DATA_EXPORT where UQUARANTPROD_L is not null
      union ALL
      select UQUARANTPROD_R, id from DATA_EXPORT where UQUARANTPROD_R is not null) as export, tnved_export 
      WHERE tnved_export.code LIKE '${tnved}' and tnved_export.pid = export.id
      GROUP BY UQUARANTPROD`
export const SEARCH_TNVED_BY_PP = (pp: string) =>
    `SELECT code FROM tnved_export, data_export
     WHERE tnved_export.pid = data_export.id AND (data_export.uquarantprod_l LIKE '${pp}' OR data_export.uquarantprod_r LIKE '${pp}')
     GROUP BY code`
export const HAS_CODETNVED_BY_UQUARANTPROD = (pp: string) =>
    `SELECT id FROM data_export 
     WHERE (data_export.uquarantprod_r LIKE '${pp}' OR data_export.uquarantprod_r LIKE '${pp}') 
     AND data_export.codetnved != '' AND data_export.codetnved != ''`

export const SELECT_NOTIFICATIONS_FROM_WEEDS = `
    SELECT id, title, message, createdAt, isRead
    FROM notification
`