import React, {CSSProperties, FC} from 'react';
import Logo from '../../img/logo.png'
import Arrow from '../../img/arrowBack.png'

import './Header.scss'
import { useHistory } from 'react-router-dom';

interface HeaderProps {
    text: string
    renderTitle?: (text: string) => React.ReactElement
    onBack:  (() => void) | string
}

const Header: FC<HeaderProps> = ({renderTitle = (text) =>  <p>{text}</p>, text, onBack}) => {

    const history = useHistory()
    const handleClickBack = () => {
        if (typeof onBack == 'string') {
            history.push(`${onBack}`)
        } else {
            onBack()
        }
    }

    return (
        <div className="header">
            <img src={Logo} alt="logo" className="header-logo" onClick={() => history.push("/")}/>
            {renderTitle(text)}
            <button onClick={handleClickBack}><img className="button-image" src={Arrow} alt="back" /></button>
        </div>
    );
};

export default Header;