import {useEffect, useState} from "react";

export const useDebounce = (value: string, delay: number = 300) => {

    const [debounced, setDebounced] = useState(value)

    useEffect(() => {
        const timerID = setTimeout(() => {setDebounced(value)}, delay)
        return () => clearTimeout(timerID)
    }, [value, delay])

    return debounced
}