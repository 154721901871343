import React, {FC} from 'react';
import {INotification} from "../../interfaces/notification";
import moment from 'moment';
import cn from 'classnames'

import './NotificationCard.scss'

interface NotificationCardProps {
    notification: INotification
    onClick?: (notification: INotification) => void
}

const NotificationCard: FC<NotificationCardProps> = ({notification, onClick}) => {

    const handleClick = () => {
        console.log(notification.isRead)
        if (notification.isRead) return;
        onClick && onClick(notification)
    }

    return (
        <div className={cn('notification-card', {'notification-card-unread': !notification.isRead})} onClick={handleClick}>
            <p>{notification.title}</p>
            <p>{moment(notification.createdAt).format('DD.MM.YYYY HH:mm')}</p>
        </div>
    );
};

export default NotificationCard;