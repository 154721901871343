import React, {FC, PropsWithChildren} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import './Modal.scss';
import {IconButton} from "@mui/material";
interface ModalProps {
    isOpen: boolean
    onClose: () => void
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({isOpen, onClose, children}) => {
    return (
        <>
            {isOpen &&
                <div className='modal-wrapper'>
                    <div className='modal'>
                        <IconButton className='modal-close' aria-label='delete' onClick={onClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                        <div className='modal-body'>
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Modal;