import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css'
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import SQLProvider from "./providers/SQLProvider";
import DataBaseProvider from "./providers/DataBaseProvider";
import AlertProvider from "./providers/AlertProvider";
import NotificationProvider from "./providers/NotificationProvider";
import SocketProvider from "./providers/SocketProvider";

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
      <AlertProvider>
          <SQLProvider>
              <DataBaseProvider>
                  <NotificationProvider>
                      <SocketProvider>
                          <App />
                      </SocketProvider>
                  </NotificationProvider>
              </DataBaseProvider>
          </SQLProvider>
      </AlertProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();