import React, {CSSProperties, FC} from 'react';
import { Link } from 'react-router-dom';

import './Button.scss'
import {IconButton} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {WithHelp} from "../../interfaces/ui";

interface ButtonProps extends WithHelp {
    text: string
    href: string
    isActive: boolean
    style?: CSSProperties
}

const Button: FC<ButtonProps> = ({ text, href, isActive, helpText, onClickHelp , style}) => {

    const buttonClass = isActive ? "green-button" : "green-button disable"

    const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!helpText || !onClickHelp) return;
        event.preventDefault();
        event.stopPropagation();
        onClickHelp(helpText)
    }

    return (
        <>
            <Link to={isActive ? href : "/"} className={buttonClass} style={style}>
                {text}
                {helpText && <IconButton aria-label="help" className='help-icon white' onClick={handleHelpClick}>
                    <HelpOutlineOutlinedIcon />
                </IconButton>}
            </Link>
        </>
    );
};

export default Button;