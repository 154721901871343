import {useEffect, useState} from "react";

export const useGetData = <T>(url: string, defer: boolean = false, config?: RequestInit): [T | null, boolean, () => Promise<void>] => {

    const [data, setData] = useState<T | null>(null)
    const [loading, setLoading] = useState(true)

    const handleGetData = async () => {
        try {
            const response = await fetch(url, config)
            const result = await response.json()

            if (response.ok) {
                setData(result)
            } else {
                throw new Error(result)
            }
        } catch(error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (defer) return;
        handleGetData()
    }, [defer])

    return [data, loading, handleGetData]
}