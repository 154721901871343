import React, {FC, useState} from 'react';
import './ActiveIcon.scss'

interface ActiveIconProps {
    name: string
    icon: string
    onClick: () => void
}

const ActiveIcon: FC<ActiveIconProps> = ({name, icon, onClick}) => {

    const [img, setImg] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();

        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            const image = event.currentTarget.files[0];
            const srcImage = URL.createObjectURL(image)
            reader.readAsDataURL(image);
            reader.onload = function () {
                console.log(reader.result)
            }
            setImg(srcImage)
        }
    }

    return (
        <>
            <label htmlFor={name} className={'active-icon_label'}>
                <input accept="image/*" className={'hide-input'} type="file" capture="environment" id={name} onChange={handleChange}/>
                <div className="active-icon_wrap">
                    <img src={icon} alt={name} className="active-icon"/>
                </div>
            </label>
            {img && <img src={img} alt="" style={{width: '350px', height: '400px', marginBottom: '20px'}}/>}
        </>
    );
};

export default ActiveIcon;