import React, {FC} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {Control, Controller} from 'react-hook-form';
import VoiceSearch from '../VoiceSearch/VoiceSearch'
import {useDebounceFunc} from "../../hooks/useDebounceFunc";

import './ControlledAutocomplete.scss'
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {IconButton} from "@mui/material";
import {WithHelp} from "../../interfaces/ui";

interface ControlledAutocompleteProps extends WithHelp {
    control: Control<any, object>
    options: string[]
    onSearch: (str: string) => void
    onSelect: () => void
    title: string
    nameInput: string
    rules?: { [key: string]: any }
    transform?: (v: string) => string
    voiceSearch?: boolean
}


const ControlledAutocomplete: FC<ControlledAutocompleteProps> = ({
                                                                     control,
                                                                     options,
                                                                     onSearch,
                                                                     onSelect,
                                                                     title,
                                                                     nameInput,
                                                                     rules,
                                                                     transform,
                                                                     voiceSearch= false,
                                                                     helpText,
                                                                     onClickHelp
                                                                 }) => {

    const debounceSearchFunc = useDebounceFunc(onSearch)
    const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!helpText || !onClickHelp) return;
        event.preventDefault();
        event.stopPropagation();
        onClickHelp(helpText)
    }

    return (
        <Controller
            name={nameInput}
            control={control}
            rules={rules}
            render={({field: {onChange, value}, formState: {errors}}) => <Autocomplete
                disablePortal
                options={options}
                openOnFocus
                filterOptions={(options) => options}
                renderInput={(params) =>
                    <div ref={params.InputProps.ref} className="search-block">
                        <label htmlFor={nameInput} className="search-label">
                            {title}
                            {rules?.required && <span style={{color: 'red'}}>*</span>}
                        </label>
                        <input type="text" {...params.inputProps} placeholder="Поиск..." name={nameInput}
                               className={`search-input  ${errors[nameInput] ? 'invalid' : ''}`}/>
                        <div className='helper_wrapper'>
                            {helpText && <IconButton aria-label="help" onClick={handleClickHelp} style={{color: '#80808087'}}>
                                <HelpOutlineOutlinedIcon />
                            </IconButton>}
                            {voiceSearch && <VoiceSearch onSetValue={(text: string) => {
                                const input = document.querySelector(
                                    `input[name="${nameInput}"]`
                                ) as HTMLInputElement | null
                                input?.focus();
                                input?.click();
                                onChange(transform ? transform(text) : text)
                                onSearch(text)
                            }}/>}
                        </div>
                        {errors[nameInput] && <span className='error-message'>{errors[nameInput]?.message}</span>}
                    </div>}
                inputValue={value}
                onInputChange={(e, val, reas) => {
                    onChange(transform ? transform(val) : val)
                    debounceSearchFunc(val as string)
                }}
                onChange={(e, val, reas) => {
                    /*e.preventDefault();
                    e.stopPropagation();*/
                    if (reas === 'selectOption') {
                        onChange(val)
                    }
                }}
            />}
        />
    );
};

export default ControlledAutocomplete;