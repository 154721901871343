import React, {Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useState} from 'react';
import {DataBaseContext} from '../contexts/DataBaseContext';
import initSqlJs, {Database} from "sql.js";
import {FileNames} from "../interfaces/interfaces";
import localforage from "localforage";
import {useSQLService} from "../hooks/useSQLService";
import {DATA_EXPORT_DATABASE, KFZ_DATABASE, NOTIFICATION_DATABASE, QUARANTS_DATABASE} from "../constance/dataBaseNames";
import {saveDataBase} from "../share/utils";
import {CREATE_NOTIFICATION_DB} from "../constance/createQuery";

interface DataBaseProviderProps {

}

const DataBaseProvider = ({children}: PropsWithChildren<DataBaseProviderProps>) => {

    const {SQLService} = useSQLService()
    const [kfzDb, setKfzDb] = useState<Database | null>(null)
    const [quarantDb, setQuarantDb] = useState<Database | null>(null)
    const [dataExportDb, setDataExportDb] = useState<Database | null>(null)
    const [notificationDb, setNotificationDb] = useState<Database | null>(null)
    const [error, setError] = useState<any>(true)

    const loadNotificationDb = useCallback(async () => {
        if (!SQLService) return;
        const notificationDataBase = await localforage.getItem<Uint8Array | null>(NOTIFICATION_DATABASE)
        if (notificationDataBase) {
            setNotificationDb(new SQLService.Database(notificationDataBase))
        } else {
            setNotificationDb(new SQLService.Database())
        }
    }, [SQLService])


    const loadKfzDb = useCallback(() => {
        if (!SQLService) return;
        loadDB(SQLService, KFZ_DATABASE, setKfzDb)
    }, [SQLService])

    const loadQuarantsDb = useCallback(() => {
        if (!SQLService) return;
        loadDB(SQLService, QUARANTS_DATABASE, setQuarantDb)
    }, [SQLService])

    const loadDataExport = useCallback(() => {
        if (!SQLService) return;
        loadDB(SQLService, DATA_EXPORT_DATABASE, setDataExportDb)
    }, [SQLService])

    useEffect(() => {
        loadKfzDb()
        loadQuarantsDb()
        loadDataExport()
        loadNotificationDb()
    }, [loadKfzDb, loadQuarantsDb, loadDataExport, loadNotificationDb])

    useEffect(() => {
        if (!notificationDb) return;
        notificationDb.run(CREATE_NOTIFICATION_DB)
        saveDataBase(NOTIFICATION_DATABASE, notificationDb)
    }, [notificationDb]);

    const loadDB = async (sqlService: any, fileName: FileNames, setFunction: Dispatch<SetStateAction<Database | null>>) => {
        const allLength = await localforage.getItem<string | null>(fileName + '_length')
        const db = await localforage.getItem<Uint8Array | null>(fileName + '_db')
        if (allLength && db) {
            const kfzCurrentLength = db.byteLength
            if (+allLength === kfzCurrentLength) {
                setFunction(new sqlService.Database(db))
                setError('')
            }
        }
    }

    return (
        <DataBaseContext.Provider value={{
            kfzDb,
            quarantDb,
            dataExportDb,
            loadKfzDb,
            loadQuarantsDb,
            notificationDb,
            loadDataExport,
            error
        }}>
            {children}
        </DataBaseContext.Provider>
    );
};

export default DataBaseProvider;