import React, {FC, useEffect, useState} from 'react';
import Auth from '../services/auth'
import {host, hostIdentity} from "../environments/environments";

interface PrivateRouteProps {
    children: React.ReactElement
}

const authorization = (res: Response): Promise<Response> => new Promise((resolve, reject) => {
    if (res.redirected) {
        window.location.href = res.url
    }
    resolve(res)
})

const PrivateRoute: FC<PrivateRouteProps> = ({children, ...rest}) => {

    const [isAuth, setIsAuth] = useState(process.env.NODE_ENV !== 'production')

    useEffect(() => {
        //TODO: потом убрать
        process.env.NODE_ENV === 'production' && fetch(`${hostIdentity}/Authorization/verification`, {
            method: "GET",
            redirect: 'follow'
        })
            .then(authorization)
            .then(res => res.json())
            .then((user) => {
                setIsAuth(true)
            })
            .catch(err => console.log(err))
    }, [])

    if (!isAuth) {
        return null
    }

    return (
        <>
            {children}
        </>
    )

};

export default PrivateRoute;

