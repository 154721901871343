import {styled} from "@mui/material/styles";
import {MenuItem} from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(() => ({
    '&.Mui-selected': {
        backgroundColor: 'rgba(115, 193, 32, 0.6)',
    },
    '&.Mui-selected.Mui-focusVisible': {
        backgroundColor: 'rgba(115, 193, 32, 0.6)',
    },
    '&.Mui-selected:hover': {
        backgroundColor: 'rgba(115, 193, 32, 0.6)',
    },
    '&:hover': {
        backgroundColor: 'rgba(115, 193, 32, 0.3)',
    }
}))

export default StyledMenuItem