import React, {PropsWithChildren, useEffect, useState} from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { useAlerts } from '../hooks/useAlerts';
import { INotification } from '../interfaces/notification';
import {useDataBase} from "../hooks/useDataBase";
import {addNotificationToDb, deleteNotification, getNotifications} from "../services/fetchData";
import {compareDate, saveDataBase} from "../share/utils";
import {IAlertTypes} from "../interfaces/alert";
import {v4 as uuid} from 'uuid'
import { useHistory } from 'react-router';
import {NOTIFICATION_DATABASE} from "../constance/dataBaseNames";
import {host} from "../environments/environments";

const DELETED_LIMIT_NOTIFICATIONS = 30

interface NotificationProviderProps {

}
const NotificationProvider = ({children}: PropsWithChildren<NotificationProviderProps>) => {

    const {notificationDb} = useDataBase()

    const [notifications, setNotifications] = useState<Array<INotification>>([])

    useEffect(() => {
        if (!notificationDb) return;
        let notifications = getNotifications(notificationDb).sort(compareDate)
        const notificationsLength = notifications.length
        if (notificationsLength > DELETED_LIMIT_NOTIFICATIONS) {
            const deletedNotificationIds = deleteNotification(notificationDb, notificationsLength - DELETED_LIMIT_NOTIFICATIONS)
            const deletedNotificationIdsMap = deletedNotificationIds?.reduce((acc, next) => {
                acc = {...acc, [next.toString()]: true}
                return acc
            }, {} as Record<string, boolean>)
            if (!deletedNotificationIdsMap) return;
            notifications = notifications.filter(notification => !deletedNotificationIdsMap[notification.id]);
        }
        setNotifications(notifications)
        notifications.forEach(notification => {});
        (async () => {
            await saveDataBase(NOTIFICATION_DATABASE, notificationDb)
        })()
    }, [notificationDb]);

    const addNotification = async (newNotification: INotification) => {
        setNotifications(notifications => [...notifications, newNotification])
    }

    const readNotification = async (notification: INotification) => {

        if (notification.isRead) return;

        const response = await fetch(host + '/notification/read', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: notification.id})
        })
        if (!response.ok) return;

        if (!notificationDb) return;
        const savedNotification = addNotificationToDb(notificationDb, notification)
        if (!savedNotification) return;

        setNotifications(existNotifications => existNotifications.map(existNotification => {
            if (existNotification.id.toString() === notification.id.toString()) {
                return {
                    ...existNotification,
                    isRead: true
                }
            }
            return existNotification
        }))
        await saveDataBase(NOTIFICATION_DATABASE, notificationDb)
    }

    return (
        <NotificationContext.Provider value={{
            notifications,
            addNotification,
            readNotification
        }}>
            {notificationDb ? children : null}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;