import React, {FC, useMemo} from 'react';
import {IconButton, SxProps} from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useHistory} from "react-router-dom";
import {useNotifications} from "../../hooks/useNotifications";

import './NotificationBell.scss';
import {WithHelp} from "../../interfaces/ui";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const NOTIFICATION_BUTTON_STYLE: SxProps = {
    position: 'absolute',
    top: '0',
    right: '0'
}

const NOTIFICATION_ICON_STYLE: SxProps = {
    width: '40px',
    height: '40px',
    color: '#73C120'
}

const HELP_BUTTON_STYLE: SxProps = {
    position: 'absolute',
    top: '60px',
    right: '11px',
    color: 'grey'
}

interface NotificationBellProps extends WithHelp {

}

const NotificationBell: FC<NotificationBellProps> = ({onClickHelp, helpText}) => {

    const history = useHistory()
    const {notifications} = useNotifications()
    const unreadNotificationCount = useMemo(() => {
        return notifications.filter(notification => !notification.isRead).length
    }, [notifications])

    const handleClickNotification = () => {
        history.push('notifications')
    }

    const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClickHelp && helpText &&  onClickHelp(helpText)
    }

    return (
        <>
            {helpText && <IconButton aria-label="help" className='help-icon' sx={HELP_BUTTON_STYLE} onClick={handleClickHelp}>
                <HelpOutlineOutlinedIcon />
            </IconButton>}
            {unreadNotificationCount !== 0 ?
                <IconButton aria-label="delete" sx={NOTIFICATION_BUTTON_STYLE} size="large"
                            onClick={handleClickNotification}>
                    <div className="notificationCounter"><p>{unreadNotificationCount}</p></div>
                    <NotificationImportantIcon sx={NOTIFICATION_ICON_STYLE}/>
                </IconButton> :
                <IconButton aria-label="delete" sx={NOTIFICATION_BUTTON_STYLE} size="large"
                            onClick={handleClickNotification}>
                    <NotificationsIcon sx={NOTIFICATION_ICON_STYLE}/>
                </IconButton>
            }
        </>
    );
};

export default NotificationBell;