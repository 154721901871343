export const HELPERS: Record<string, string> = {
    'import': 'Раздел предназначен для поиска фитосанитарных требований к подкарантинной продукции, которая ввозится на территорию Российской федерации. Для выполнения поиска необходимо заполнить поля «Подкарантинная продукция» и «Страна экспорта» (страна вывоза продукции).',
    'export': 'Раздел предназначен для поиска фитосанитарных требований к подкарантинной продукции, которая вывозится с территории Российской федерации в другие страны. Для выполнения поиска необходимо заполнить поля «Подкарантинная продукция» и «Страна импорта» (страна ввоза продукции).',
    'directory': 'Раздел содержит перечень карантинных объектов, утвержденный Решением Совета Евразийской экономической комиссии от 30 ноября 2016 г. № 158. Записи в разделе сгруппированы по признаку «отсутствующие» и «ограниченно распространенные», а также по виду карантинного объекта.',
    'kfz': 'Раздел содержит перечень карантинных фитосанитарных зон, установленных на территории Российской Федерации. Записи в разделе сгруппированы по регионам и карантинным объектам.',
    'support': 'Раздел предназначен для обеспечения взаимодействия с технической поддержкой. Для формирования запроса для технической поддержки необходимо заполнить все обязательные поля формы и нажать «Отправить». Ограничение в отправке файлов - до 5 МБ.',
    'pp_import': 'Вводится наименование подкарантинной продукции, которая ввозится на территорию Российской федерации. При вводе первых трех символов, будет выведен список доступной подкарантинной продукции.',
    'country_import': 'Вводится наименование страны экспорта – страна, с территории которой осуществляется ввоз подкарантинной продукции на территорию Российской Федерации. При вводе первых трех символов будет доступен список стран.',
    'pp_export': 'Вводится наименование подкарантинной продукции, которая вывозится с территории Российской федерации в другую страну. При вводе первых трех символов, будет выведен список доступной подкарантинной продукции.',
    'country_export': 'Вводится наименование страны импорта – страна, на территорию которой осуществляется ввоз подкарантинной продукции с территории Российской Федерации. При вводе первых трех символов будет доступен список стран.',
    'update': 'При обновлении фитосанитарных требований загружаются данные по выбранным странам. При необходимости, имеется возможность добавить страну путем ввода первых трех символов и выбора страны из списка или удалить страну по крестику.',
    'nsi': 'Раздел предназначен для просмотра и анализа перечня документов, содержащих фитосанитарные требования стран. Документы в разделе сгруппированы по каждой из стран.',
    'notification': 'В данном разделе хранятся непрочитанные оповещения о внесении изменений в фитосанитарные требования. '
}
