import {environmentAPI, host} from '../environments/environments'
import { User } from 'oidc-client'
import localforage from "localforage";
import {HaveUpdateRequestBody, HaveUpdateResponse} from "../interfaces/interfaces";

export const getDb = (user: User) => {
    let url = environmentAPI.getDb;
    let jwt = "Bearer " + user.access_token;
    return fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': jwt,
        }
    }).then(res => res.blob())
}

export const getHash = (user: User) => {
    let url = environmentAPI.getHash;
    let jwt = "Bearer " + user.access_token
    return fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': jwt,
        }
    }).then(res => res.text())
}

export const getNeedUpdate = async () => {
    const kfzDbHash = await localforage.getItem<string | null>('kfz_hash')
    const quarantObjHash = await localforage.getItem<string | null>('quarants_hash')
    const dataExportHash = await localforage.getItem<string | null>('data_export_hash')

    let body: HaveUpdateRequestBody = {
        dataExport_Hash: dataExportHash,
        kfz_Hash: kfzDbHash,
        quarant_Hash: quarantObjHash
    }

    try {
        const response = await fetch(`${host}/mobile/haveupdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        if (response.ok) {
            const haveUpdate: HaveUpdateResponse = await response.json()
            return haveUpdate
        }
    } catch(e) {
        console.log(e)
    }
}

