import React, {useEffect, useState} from 'react';
import Header from "../components/Header/Header";
import {Controller, useForm} from "react-hook-form";
import {FormControl, Select} from '@mui/material';
import StyledInput from '../components/StyledInput/StyledInput';
import StyledMenuItem from "../components/StyledMenuItem/StyledMenuItem";
import {transformPhone, validatePhone} from "../share/utils";
import {host} from "../environments/environments";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../components/Modal/Modal";

import './SupportPage.scss';
import {SmartCaptcha} from "@yandex/smart-captcha";

interface InputValues {
    theme: string
    fio: string
    description: string
    phone: string
    email: string
    files: Array<File>
}

const THEME_OPTIONS = [
    'Запрос консультации',
    'Системная ошибка',
    'Запрос на обслуживание',
    'Запрос на доработку'
]

const PHONE_REGEX =
    '(?:(^[\\+]?[0-9]?[ ]?[(]?[0-9]{3}[)]?[ ]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{2}?[-\\s\\.]?[0-9]{2}?$)|(^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$))'

const EMAIL_REGEX =
    '(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
const SupportPage = () => {

    const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm<InputValues>({
        defaultValues: {
            theme: '',
            fio: '',
            description: '',
            phone: '',
            email: '',
            files: []
        }
    })

    const [token, setToken] = useState('')
    const [statusMessage, setStatusMessage] = useState<'default' | 'loading' | 'success' | 'error' | 'errorSize'>('default')
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        fetch(host + '/support')
            .then(response => {
                if (!response.ok) {
                    return Promise.reject('Ошибка получения информации о пользователе');
                }
                return response.json()
            })
            .then((user: InputValues) => {
                setValue('phone', user.phone)
            })
            .catch(err => {
                console.error(err)
            })
    }, [setValue]);

    const onSubmit = (data: InputValues) => {
        setStatusMessage('loading')
        const {theme, fio, description, phone, email, files} = data
        let filesSize = 0

        const formData = new FormData()
        formData.append('theme', theme)
        formData.append('fio', fio)
        formData.append('description', description)
        formData.append('phone', phone)
        formData.append('email', email)
        formData.append('token', token)

        for (let i = 0; i < files.length; i++) {
            filesSize += files[i].size
            if (filesSize > 5000000) {
                setIsOpen(true)
                setStatusMessage('errorSize')
                return;
            }
            formData.append('files', files[i])
        }

        fetch(host + '/support', {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage('success')
                    reset({
                        fio: '',
                        theme: '',
                        files: [],
                        email: '',
                        description: '',
                        phone
                    })
                } else {
                    setStatusMessage('error')
                }
                setIsOpen(true)
            })
            .catch((error) => {
                setStatusMessage('error')
                setIsOpen(true)
            })
    }

    return (
        <div className="wrap">
            <Header text='Техническая поддержка' renderTitle={(text) => <p style={{fontSize: '20px'}}>{text}</p>} onBack="/"/>
            <form
                className="default-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='form-field'>
                    <label htmlFor='theme' className="search-label">
                        Тема сообщения
                    </label>
                    <Controller
                        name='theme'
                        control={control}
                        rules={{required: 'Поле обязательно для заполнения!'}}
                        render={({field: {value, onChange, name}, fieldState: {error}}) =>
                            <FormControl sx={{width: '100%', marginTop: '10px'}}>
                                <Select
                                    autoFocus={false}
                                    displayEmpty
                                    value={value}
                                    onChange={onChange}
                                    input={<StyledInput/>}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return 'Тема сообщения';
                                        }
                                        return selected;
                                    }}
                                    inputProps={{'aria-label': 'Without label', className: `${error ? 'invalid' : ''}`}}
                                >
                                    {THEME_OPTIONS.map(theme => <StyledMenuItem key={theme}
                                                                                value={theme}>{theme}</StyledMenuItem>)}
                                </Select>
                                {error && <span className='error-message'>{error.message}</span>}
                            </FormControl>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor='fio' className="search-label">
                        ФИО
                    </label>
                    <input {...register('fio', {required: 'Поле обязательно для заполнения!'})} type='text' id='fio'
                           className={`search-input  ${errors.fio ? 'invalid' : ''}`} placeholder="Введите ваше ФИО"/>
                    {errors.fio && <span className='error-message'>{errors.fio.message}</span>}
                </div>
                <div className='form-field'>
                    <label htmlFor='description' className="search-label">
                        Описание
                    </label>
                    <textarea {...register('description', {required: 'Поле обязательно для заполнения!'})}
                              id='description' placeholder='Введите описание проблемы' style={{marginTop: '10px'}}
                              className={`search-input  ${errors.description ? 'invalid' : ''}`}
                    />
                    {errors.description && <span className='error-message'>{errors.description.message}</span>}
                </div>
                <div className='form-field'>
                    <label htmlFor='phone' className="search-label">
                        Телефон
                    </label>
                    <Controller
                        control={control}
                        name='phone'
                        rules={{
                            required: 'Поле обязательно для заполнения!',
                            pattern: {
                                value: new RegExp(PHONE_REGEX),
                                message: 'Некорректный телефон!'
                            }
                        }}
                        render={({field: {value, onChange}, fieldState: {error}}) =>
                            <>
                                <input
                                    value={value}
                                    onChange={(event) => onChange(validatePhone(event))}
                                    type='text'
                                    id='phone'
                                    className={`search-input  ${error ? 'invalid' : ''}`}
                                    placeholder="Введите ваш телефон"
                                />
                                {error && <span className='error-message'>{error.message}</span>}
                            </>
                        }
                    />
                </div>
                <div className='form-field'>
                    <label htmlFor='email' className="search-label">
                        Email
                    </label>
                    <input {...register('email', {
                        required: 'Поле обязательно для заполнения!', pattern: {
                            value: new RegExp(EMAIL_REGEX),
                            message: 'Некорректный email!'
                        }
                    })} placeholder='Введите ваш email' id='email' type='text'
                           className={`search-input  ${errors.email ? 'invalid' : ''}`}/>
                    {errors.email && <span className='error-message'>{errors.email.message}</span>}
                </div>
                <div className='form-field'>
                    <label className="search-label" style={{display: 'block'}}>
                        Файлы
                    </label>
                    <Controller
                        control={control}
                        name='files'
                        render={({field: {value, onChange}}) =>
                            <div className='file-uploader_wrapper'>
                                {value && value.map(file => <p key={file.name}
                                                               style={{wordBreak: 'break-all'}}>{file.name}</p>)}
                                <label htmlFor='files' className='green-button upload-btn'
                                       style={{display: 'inline-block', marginTop: '10px'}}>
                                    <input type='file' id={'files'} onChange={(event) => {
                                        if (!event.currentTarget.files || event.currentTarget.files.length === 0) return;
                                        onChange(Array.from(event.currentTarget.files))
                                    }} multiple accept="image/*"/>
                                    <div className='green-button upload-btn'>Загрузить файлы</div>
                                </label>
                            </div>
                        }
                    />
                </div>
                <div className='form-field' style={{marginTop: '10px'}}>
                    <SmartCaptcha sitekey="ysc1_X2u2w3XDClDmIzq70vhdBoC0crpku0TpF4EvQkpN22f9dfe9" onSuccess={setToken} />
                </div>
                <button className={`green-button ${!token ? ' disabled' : ''}`} style={{marginBottom: '10px', marginTop: '10px'}} type="submit" disabled={!token}>
                    {statusMessage === 'loading' && <>
                        <CircularProgress size={30} sx={{color: 'white', marginRight: '10px'}}/>
                        Отправка...
                    </>}
                    {statusMessage !== 'loading' && 'Отправить'}
                </button>
            </form>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {statusMessage === 'error' &&
                    <p className={'notification-message error'}>Произошла ошибка при отправке, попробуйте повторить
                        попытку позже!</p>}
                {statusMessage === 'errorSize' &&
                    <p className={'notification-message error'}>Слишком большой размер файлов, максимальный размер
                        5мб!</p>}
                {statusMessage === 'success' &&
                    <p className={'notification-message success'}>Ваша заявка отправлена! Просьба ожидать связи с
                        консультантом технической поддержки по указанному контактному адресу электронной почты.</p>}
            </Modal>
        </div>
    );
};

export default SupportPage;