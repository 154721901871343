import React, {FC, useEffect, useState} from 'react';
import {getFile} from "../services/fetchData";
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {useLocation, useHistory} from "react-router-dom";
import {Database} from "sql.js";
import '@react-pdf-viewer/core/lib/styles/index.css';
import Header from "../components/Header/Header";
import CircularProgress from "@mui/material/CircularProgress";

import './PDFViewerPage.scss';

interface PdfViewerPageProps {
    db: Database | null
}

const PdfViewerPage: FC<PdfViewerPageProps> = ({db}) => {

    const location = useLocation()
    const history = useHistory()
    const [file, setFile] = useState<Uint8Array | null>(null)
    const [header, setHeader] = useState('')

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const id = query.get('id')
        const fileName = query.get('fileName')
        const tableName = query.get('tableName')
        if (!id || !fileName || !tableName || !db) return;
        const file = getFile(fileName, tableName, db, id)
        if (!file) return

        setFile(file.file)
        setHeader(file.name)
    }, [db, location])

    return (
        <div className={'pdf-wrapper'}>
            <Header
                text={header}
                onBack={() => {history.goBack()}}
                renderTitle={(text) =>
                    <p style={{
                        fontSize: '14px',
                        maxWidth: '50%',
                        textAlign: 'center'
                    }}>{text}</p>
                }
            />
            <Worker workerUrl="pdf.worker.min.js">
                {file &&
                    <Viewer fileUrl={file} renderLoader={() => <CircularProgress sx={{color: '#73c120'}} />}/>
                }
            </Worker>
        </div>
    );
};

export default PdfViewerPage;