import React from 'react';

const OfflinePage = () => {

    return (
        <div>
            Offline mode
        </div>
    );
};

export default OfflinePage;