import React, {PropsWithChildren, useState} from 'react';
import {AlertContext} from "../contexts/AlertContext";
import {IAlertType} from "../interfaces/alert";

interface AlertProviderProps {

}
const AlertProvider = ({children}: PropsWithChildren<AlertProviderProps>) => {

    const [alerts, setAlerts] = useState<Array<IAlertType>>([])

    const addAlert = (alert: IAlertType) => {
        setAlerts(existAlerts => [
            ...existAlerts,
            {
                ...alert
            }
        ])
    }

    const removeAlert = (alert: IAlertType) => {
        setAlerts(existAlerts => existAlerts.filter(existAlert => existAlert.id !== alert.id))
    }

    const removeAlertById = (id: string) => {
        setAlerts(existAlerts => existAlerts.filter(existAlert => existAlert.id !== id))
    }

    return (
        <AlertContext.Provider value={{
            alerts,
            addAlert,
            removeAlert,
            removeAlertById
        }}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;