export const host = process.env.NODE_ENV === 'production' ? '/s' : 'https://m.vniikr.dev.asbc.lo/s'
export const hostIdentity = process.env.NODE_ENV === 'production' ? '' : 'https://m.vniikr.dev.asbc.lo'

export const environmentAuth = {
    stsAuthority: `${host}`,
    clientId: 'mobile_client',
    responseType: 'code',
    clientScope: 'openid profile offline_access',
    redirectUri: `${host}/callback`,
    login: `${host}/Account/Login`
}

export const environmentAPI = {
    getDb: `${host}/snapshot`,
    getHash: `${host}/snapshot/hash`
}

export const METADATA_OIDC = {
    issuer: `${host}`,
    jwks_uri: `${host}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${host}/connect/authorize`,
    token_endpoint: `${host}/connect/token`,
    userinfo_endpoint: `${host}/connect/userinfo`,
    end_session_endpoint: `${host}/connect/endsession`,
    check_session_iframe: `${host}/connect/checksession`,
    revocation_endpoint: `${host}/connect/revocation`,
    introspection_endpoint: `${host}/connect/introspect`
};
  //  postLogoutRedirectUri: `${host}`, https://coderoad.ru/53824770/PostLogoutRedirectUri-%D0%B2%D1%81%D0%B5%D0%B3%D0%B4%D0%B0-null-%D0%B2-identity-Server-4-%D1%81-SPA-Angular-7-OIDC
