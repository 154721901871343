import React, { FC, useEffect } from 'react';
import { Database } from 'sql.js';
import Header from '../components/Header/Header'
import WrapAccordion from '../components/WrapAccordion/WrapAccordion';
import { IDistrictKfz, IKFZ, IQuarantObj } from '../interfaces/interfaces'
import { Accordion, AccordionSummaryGreenExpand, AccordionDetails } from '../share/accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getRegions, getQuarantObj, getDistrictKfz } from '../services/fetchData';

import './KfzDirectory.scss'

interface KfzDirectoryProps {
    onSetKfz: (value: React.SetStateAction<IKFZ>) => void
    db: Database
    kfzObj: IKFZ
}

const KfzDirectory: FC<KfzDirectoryProps> = ({ db, onSetKfz, kfzObj }) => {

    useEffect(() => {
        if (db) {
            onSetKfz(prev => Object.assign({}, prev, {
                ...getRegions(db)
            }))
        }
    }, [db, onSetKfz])

    const handleSetRegionsKfz = (quarantObjName: string) => (event: React.MouseEvent<HTMLDivElement>) => {
        const qObjsKfz: IQuarantObj[] = getQuarantObj(db, quarantObjName)
        onSetKfz(state => Object.assign({}, state, {
            regions: [...state.regions.map((obj, idx) => {
                if (obj.name === quarantObjName) {
                    return Object.assign({}, obj, {
                        quarantObj: qObjsKfz
                    })
                }
                return obj
            })]
        }))
    }

    const handleSetDistrictKfz = (regionName: string, quarantObjName: string) => (event: React.MouseEvent<HTMLDivElement>) => {
        const districtKfz: IDistrictKfz[] = getDistrictKfz(db, regionName, quarantObjName)
        onSetKfz(state => Object.assign({}, state, {
            regions: [...state.regions.map((obj, idx) => {
                if (obj.name === regionName) {
                    return {
                        ...obj,
                        quarantObj: [...obj.quarantObj.map((qObj, idx) => {
                            if (qObj.name === quarantObjName) {
                                return {
                                    ...qObj,
                                    district: districtKfz
                                }
                            }
                            return qObj
                        })]
                    }
                }
                return obj
            })]
        }))
    }

    return (
        <div className="wrap">
            <Header text='Справочник КФЗ' onBack="/" />
            <WrapAccordion>
                <>
                    {kfzObj.regions.map((reg) => {
                        return <Accordion key={reg.name}>
                            <AccordionSummaryGreenExpand
                                expandIcon={<ExpandMoreIcon />}
                                onClick={handleSetRegionsKfz(reg.name)}
                            >
                                <b>{reg.name}</b>
                            </AccordionSummaryGreenExpand>
                            <AccordionDetails>
                                <div className="kfz-details-wrapper">
                                    {reg.quarantObj.map((quarantObj, idx) => {
                                        return <Accordion key={idx}>
                                            <AccordionSummaryGreenExpand
                                                expandIcon={<ExpandMoreIcon />}
                                                onClick={handleSetDistrictKfz(reg.name, quarantObj.name)}
                                            >
                                                <b>{quarantObj.name}</b>
                                            </AccordionSummaryGreenExpand>
                                            <AccordionDetails>
                                                {quarantObj.district.map((dist, idx) => <div
                                                    key={idx}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    {dist.name && <p>Район: {dist.name}</p>}
                                                    {dist.area && <p>Площадь: {dist.area} {dist.munits && '(' + dist.munits + ')'}</p>}
                                                    {dist.quant && <p>Количество установленных КФЗ: {dist.quant}</p>}
                                                </div>)}
                                            </AccordionDetails>
                                        </Accordion>
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </>
            </WrapAccordion>
        </div>
    );
};

export default KfzDirectory;