import {saveLog} from "../services/logging";
import {useEffect} from "react";

const {fetch: originalFetch} = window;

export const useInterceptor = (activate: boolean) => {

    useEffect(() => {

        if (!activate) return;
        window.fetch = async (...args) => {
            let [resource, config] = args;

            try {
                const response = await originalFetch(resource, config);


                if (!resource.toString().endsWith('log')) {

                    deleteFilesFromBodyFormData(config?.body)
                    let loggedBody = convertToString(config?.body)

                    if (response.ok) {
                        saveLog({
                            type: 'fetch',
                            status: 'success',
                            uri: resource.toString(),
                            method: config?.method || '',
                            body: loggedBody,
                            timestamp: new Date().toISOString()
                        })
                    }

                    if (!response.ok) {
                        saveLog({
                            type: 'fetch',
                            status: 'error',
                            uri: resource.toString(),
                            method: config?.method || '',
                            body: loggedBody,
                            timestamp: new Date().toISOString()
                        })
                    }
                }

                return response;

            } catch (err) {
                saveLog({
                    type: 'fetch',
                    status: 'error',
                    uri: resource.toString(),
                    method: config?.method || '',
                    body: null,
                    message: JSON.stringify(err),
                    timestamp: new Date().toISOString()
                })
                return Promise.reject(err);
            }
        };
    }, [activate])
}

const deleteFilesFromBodyFormData = (body: BodyInit | null | undefined) => {
    if (body && body instanceof FormData) {
        for (let pair of body.entries()) {
            if (pair[1] instanceof File) {
                body.delete(pair[0])
            }
        }
    }
}

const convertToString = (body: BodyInit | null | undefined): string | null => {
    if (!body) return null;
    if (body instanceof FormData) {
        return JSON.stringify([...body.entries()].map(pair => ({[pair[0]]: pair[1]})))
    }
    return JSON.stringify(body)
}