import {useState} from "react";

export const useDebounceFunc = <T extends (...params: any[]) => void>(func: T, delay: number = 500): T => {

    const [timeoutID, setTimeoutID] = useState<number | null>(null)

    return function(this: any, ...args: any[]) {
        if (timeoutID) {
            clearTimeout(timeoutID)
        }
        setTimeoutID(window.setTimeout(() => {func.apply(this, args)}, delay))
    } as T;
}