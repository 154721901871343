import {
    IDirectory,
    IDirectoryData,
    IDistrictKfz,
    IKFZ,
    IObject,
    IQuarantObj, QUARANTOBJ, QUARANTOBJBYCNTR,
    QUARANTOBJBYPPANDCNTR, Uint8ArrayFileWithName
} from "../interfaces/interfaces"
import levenshtein from "js-levenshtein";
import {QueryExecResult, SqlValue} from 'sql.js'
import {INotification} from "../interfaces/notification";

export const findItemsInQueryResult = (search: string, items: string[], dictionary: string[] = [], method: 'startWith' | 'includes'): string[] => {
    let filteredItems: string[] = []
    let searchStr = ''
    const searchLowerCase = search.toLowerCase()
    if (dictionary.length !== 0) {
        searchStr = dictionary.sort((a, b) => levenshtein(searchLowerCase, a) - levenshtein(searchLowerCase, b))[0]
    } else {
        searchStr = search
    }
    if (method === 'includes') {
        filteredItems = items.map(item => item.toLowerCase()).filter(item => item.includes(searchStr.toLowerCase()))
        const firstElements = []
        const secondElements = []
        for (let i = 0; i < filteredItems.length; i++) {
            if (filteredItems[i].startsWith(search)) {
                firstElements.push(filteredItems[i])
            } else {
                secondElements.push(filteredItems[i])
            }
        }
        firstElements.sort()
        secondElements.sort()
        filteredItems = [...firstElements, ...secondElements]
    }
    if (method === 'startWith') {
        filteredItems = items.map(item => item.toLowerCase()).filter(item => item.startsWith(searchStr.toLowerCase()))
    }
    return filteredItems.map(item => items[items.map(item => item.toLowerCase()).indexOf(item)])
}

export const transformSearch = (response: QueryExecResult[]): string[] => {
    const searchItems: string[] = []
    if (response && response.length !== 0) {
        response.forEach((res) =>
            res.values.forEach((row: any) => {
                searchItems.push(row[0])
            }))
    }
    return searchItems
}

export const transformObj = (response: QueryExecResult[]): IObject[] => {
    const objects: IObject[] = []
    if (response && response.length !== 0) {
        response.forEach((res) =>
            res.values.forEach((row: any) => {
                objects.push({
                    country: row[0],
                    "Требования к экспорту": {
                        'Регионы': row[1] ? JSON.parse(row[1]) : '',
                        'Регионы_зп': row[2] ? JSON.parse(row[2]) : '',
                        'Контрагенты': row[3] ? JSON.parse(row[3]) : '',
                        'Контрагенты_зп': row[4] ? JSON.parse(row[4]) : ''
                    },
                    "Требования к реэкспорту": {
                        'Регионы': row[5] ? JSON.parse(row[5]) : '',
                        'Регионы_зп': row[6] ? JSON.parse(row[6]) : '',
                        'Контрагенты': row[7] ? JSON.parse(row[7]) : '',
                        'Контрагенты_зп': row[8] ? JSON.parse(row[8]) : '',
                        'Страны': row[9] ? JSON.parse(row[9]) : '',
                        'Страны_зп': row[10] ? JSON.parse(row[10]) : ''
                    },
                    "Сопроводительные документы": row[11] ? JSON.parse(row[11]) : '',
                    "Сопроводительные документы зп": row[12] ? JSON.parse(row[12]) : '',
                    "Подкарантинная продукция": {
                        'Продукт': {
                            'Наименование RUS': row[13],
                            "Наименование LAT": row[14],
                            DOC: row[15] ? JSON.parse(row[15]) : '',
                            DOC_R: row[16] ? JSON.parse(row[16]) : '',
                        },
                        "ТН ВЭД": row[17] ? JSON.parse(row[17]) : '',
                        "ТНВ ЭД_зп": row[18] ? JSON.parse(row[18]) : '',
                        'Карантинные_объекты': row[19] ? JSON.parse(row[19]) : '',
                        'Карантинные_объекты_зп': row[20] ? JSON.parse(row[20]) : '',
                       /* "КФЗ": row[21] ? JSON.parse(row[21]) : '',*/
                        'Подкарантинные_объекты_зп': row[22] ? JSON.parse(row[22]) : '',
                        "Требования к подкарантинной продукции": {
                            OTH: row[23] ? JSON.parse(row[23]) : '',
                            OTH_R: row[24] ? JSON.parse(row[24]) : ''
                        },
                    },
                    "Места ввоза": row[25] ? JSON.parse(row[25]) : '',
                    "Места ввоза_зп": row[26] ? JSON.parse(row[26]) : '',
                    "Упаковка": row[27] ? JSON.parse(row[27]) : '',
                    "Упаковка_зп": row[28] ? JSON.parse(row[28]) : '',
                    "Транзит": row[29] ? JSON.parse(row[29]) : '',
                    "Транизт_зп": row[30] ? JSON.parse(row[30]) : '',
                    'Транспортировка': row[31] ? JSON.parse(row[31]) : '',
                    'Транспортировка_зп': row[32] ? JSON.parse(row[32]) : '',
                    'Методы_отбора_образцов': row[33] ? JSON.parse(row[33]) : ''
                })
            }))
    }
    return objects
}

export const transformQuarantObjByPPAndCntr = (response: QueryExecResult[]): QUARANTOBJBYPPANDCNTR => {
    let quarantObj: QUARANTOBJBYPPANDCNTR = {
        "Карантинные_объекты_экспорт": []
    }
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                quarantObj = JSON.parse(row[0])
            })
        })
    }
    return quarantObj
}

export const transformQuarantObjByCntr = (response: QueryExecResult[]): QUARANTOBJBYCNTR => {
    let result: QUARANTOBJBYCNTR = {}
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: SqlValue[]) => {
                result = {
                    ...JSON.parse(row[0] as string),
                    ...JSON.parse(row[1] as string)
                }
            })
        })
    }
    /*console.log('Result transformQuarantObjByCntr: ', result)*/
    return result
}

export const transformQuarantObjByCntrReference = (response: QueryExecResult[]): QUARANTOBJ => {
    let result: QUARANTOBJ = {} as QUARANTOBJ
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: SqlValue[]) => {
                result = JSON.parse(row[0] as string)
            })
        })
    }
    return result
}

export const transformGRP1DirectoryObject = (response: QueryExecResult[]): IDirectory => {
   /* const directory: IDirectory = {
        grp1: []
    }
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                directory.grp1.push({
                    title: row[0],
                    grp2: []
                })
            })
        })
    }
    return directory*/
    let directory: IDirectory = {}

    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                directory = {
                    ...directory,
                    [row[0]]: {}
                }
            })
        })
    }

    return directory
}

export const transformGRP2DirectoryObject = (response: QueryExecResult[]): {
/*    title: string,
    data: IDirectoryData[]
}[] => {

    const grp2: {
        title: string,
        data: IDirectoryData[]
    }[] = []
     if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                grp2.push({
                    title: row[0],
                    data: []
                })
            })
        })
    }
    return grp2
    */
    [key: string]: {data: IDirectoryData[]}
} => {
    let grp2: {[key: string]: {data: IDirectoryData[]}} = {}
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                grp2 = {
                    ...grp2,
                    [row[0]]: {data: []}
                }
            })
        })
    }
    return grp2
}

export const transformDataDirectoryObject = (response: QueryExecResult[]): IDirectoryData[] => {
    const data: IDirectoryData[] = []
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                data.push({
                    id: row[0],
                    KO_LAT: row[1],
                    KO_RUS: row[2],
                    DOC: row[3]
                })
            })
        })
    }
    return data
}

export const transformFile = (response: QueryExecResult[]): Uint8ArrayFileWithName | null  => {
    let file: Uint8ArrayFileWithName = {} as Uint8ArrayFileWithName
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                if (row[0].length === 0) {
                    return null
                }
                file = {
                    name: row[1] + '(' + row[2] + ')',
                    file: row[0]
                }
            })
        })
        return file
    }
    return null
}

export const transformFileId = (response: QueryExecResult[]): string | null => {
    let id = ''
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                if (row[0].length === 0) {
                    return null
                }
                id = row[0]
            })
        })
        return id
    }
    return null
}

export const transformIsFileEmpty = (response: QueryExecResult[]): boolean => {
    let isEmpty = false
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                isEmpty = !!row[0]
            })
        })
    }
    return isEmpty
}

export const transformRegionsKfz = (response: QueryExecResult[]): IKFZ => {
    const regionsKfz: IKFZ = {
        regions: []
    }
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                regionsKfz.regions.push({
                    name: row[0],
                    quarantObj: []
                })
            })
        })
    }
    return regionsKfz
}

export const transformQuarantObjKfz = (response: QueryExecResult[]): IQuarantObj[] => {
    const qObj: IQuarantObj[] = []
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                qObj.push({
                    name: row[0],
                    district: []
                })
            })
        })
    }
    return qObj
}

export const transformDisctrictKfz = (response: QueryExecResult[]): IDistrictKfz[] => {
    const districtKfz: IDistrictKfz[] = []
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                districtKfz.push({
                    name: row[0],
                    area: row[1],
                    quant: row[2],
                    munits: row[3]
                })
            })
        })
    }
    return districtKfz
}

export const transformQuarantObj = (response: QueryExecResult[]) => {

    let comfortableDirectoryObj: {
        [key: string]: {
            [key: string]: {
                data: IDirectoryData[]
            }
        }
    } = {}

    if (response && response.length !== 0) {
        response.forEach((res: QueryExecResult) => {
            res.values.forEach((row: any) => {
                const grp1 = row[5]
                const grp2 = row[6]
                comfortableDirectoryObj = {
                    ...comfortableDirectoryObj,
                    [grp1]: {
                        ...comfortableDirectoryObj[grp1],
                        [grp2]: {
                            data: [...(comfortableDirectoryObj[grp1] ? comfortableDirectoryObj[grp1][grp2] ? comfortableDirectoryObj[grp1][grp2].data : [] : []), {
                                id: row[7],
                                DOC: row[2],
                                KO_LAT: row[0],
                                KO_RUS: row[1]
                            }]
                        },
                    }
                }
            })
        })
    }

  /*  let finalObject: IDirectory = {
        grp1: []
    }

    for (let key in comfortableDirectoryObj) {
        finalObject = {
            grp1: [...finalObject.grp1, {
                title: key,
                grp2: [...(finalObject.grp1[finalObject.grp1.findIndex((elem) => elem.title === key)]?.grp2 ?
                    finalObject.grp1[finalObject.grp1.findIndex((elem) => elem.title === key)]?.grp2 : []), {
                    title: '',
                    data: []
                }]
            }]
        }
    }

    console.log({finalObject})*/

    return comfortableDirectoryObj
}

export const transformNotification = (response: QueryExecResult[]): null | INotification => {
    let notification: null | INotification = null
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                notification = {
                    id: row[0],
                    title: row[1],
                    message: row[2],
                    isRead: row[3],
                    createdAt: row[4]
                }
            })
        })
    }
    return notification
}

export const transformNotifications = (response: QueryExecResult[]): INotification[] => {
    const notifications: INotification[] = []
    if (response && response.length !== 0) {
        response.forEach((res) => {
            res.values.forEach((row: any) => {
                notifications.push({
                    id: row[0],
                    title: row[1],
                    message: row[2],
                    createdAt: row[3],
                    isRead: row[4]
                })
            })
        })
    }
    return notifications
}

export const transformToId = (response: QueryExecResult[]): null | number => {
    let returnedId: number | null = null
    response.forEach((res) => {
        res.values.forEach((row: any) => {
            returnedId = row[0]
        })
    })
    return  returnedId
}

export const transformToIds = (response: QueryExecResult[]): number[] => {
    let returnedIds: number[] = []
    response.forEach((res) => {
        res.values.forEach((row: any) => {
            returnedIds.push(row[0])
        })
    })
    return returnedIds
}