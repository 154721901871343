import {saveLog} from "../services/logging";
import {EventHandler, useEffect} from "react";

export const useDomLogs = (activate: boolean) => {
    useEffect(() => {
        if (!activate) return;
        document.addEventListener('click', (event) => {
            saveLog({
                type: 'dom',
                eventType: event.type,
                tag: (event.target as HTMLElement)?.tagName,
                innerHTML: (event.target as HTMLElement)?.innerHTML || null,
                innerText: (event.target as HTMLElement)?.innerText || null,
                name: (event.target as HTMLInputElement)?.name || null,
                timestamp: new Date().toISOString()
                /* target: event.target*/
            })
        })
    }, [activate])
}